import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Image, PasscodeInputRef } from 'antd-mobile';
import { useDirection } from 'hooks';
import { OTP_RESENT_DURATION, ROUTES } from 'constant';
import { AuthService } from 'services';
import { useLocation } from 'react-router';
import { LocalStorage, STORAGE_KEY, delayNavigate, isSuccessCode } from 'utils';
import { useEffect, useRef } from 'react';
import { VerifyOTPLoginRes } from 'types';
import { useDispatch } from 'stores';
import { setLoadingToast } from 'stores/common';
import PinInput from 'components/input/PinInput';
import { useCountdown } from 'usehooks-ts';

const Storage = new LocalStorage();

export function VerifyOTPContainer() {
  const authService = new AuthService();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const location = useLocation();
  const inputCodeRef = useRef<PasscodeInputRef>(null);
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: OTP_RESENT_DURATION,
    intervalMs: 1000,
  });

  const handleComplete = async (value: string) => {
    if (inputCodeRef.current) inputCodeRef.current.blur();
    dispatch(setLoadingToast(true));
    const response = await authService.verifyOTPLogin({
      otpCode: value,
      phoneNumber: location.state ? location.state?.phoneNumber : '',
    });
    const { code, data } = response;
    const dataRes = data as VerifyOTPLoginRes;

    if (isSuccessCode(code)) {
      Storage.setStorageItem(STORAGE_KEY.ACCESS_TOKEN, dataRes.accessToken);
      Storage.setStorageItem(STORAGE_KEY.ACCOUNT_ID, dataRes.accountId);
      delayNavigate(goTo(ROUTES.HOME, { replace: true }));
    }
  };

  const sendOTPCode = async () => {
    dispatch(setLoadingToast(true));
    const response = await authService.sendOTPLogin({
      phoneNumber: location.state?.phoneNumber,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      resetCountdown();
      startCountdown();
    }
  };

  useEffect(() => {
    if (inputCodeRef.current) inputCodeRef.current.focus();
    if (!location.state?.phoneNumber) delayNavigate(goTo(ROUTES.LADING, { replace: true }));
    startCountdown();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Image className={styles.logo} src="/images/logo/logo-new-gym-3.png" width="80px" />
        <div className={styles.form}>
          <p
            className={`font-lg color-black font-light ${styles.label}`}
            style={{
              whiteSpace: 'wrap',
              lineHeight: 'normal',
            }}
          >
            {t('paragraph.pr35')}
            <span className="color-blue font-bold">{`\n${
              location.state ? location.state?.phoneNumber : ''
            } `}</span>
            {t('paragraph.pr36')}
          </p>
          <div className={styles.input}>
            <PinInput onComplete={handleComplete} />
          </div>
        </div>
        <div className={styles.resendCode}>
          <p
            className="font-sm font-light"
            style={{
              color: 'var(--black-color)',
            }}
          >
            {t('title.did-receive-it')}
          </p>
          {count > 0 ? (
            <p className="color-black font-sm font-light">
              {t('title.get-new-code-in')} 00:{String(count).padStart(2, '0')}
            </p>
          ) : (
            <p className="color-blue font-md font-medium" onClick={sendOTPCode}>
              {t('button.get-new-code')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
