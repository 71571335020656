import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CardSwiper, SecondaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  changeSelectedContract,
  getUserContractsAction,
  getUserProfileAction,
  userSelector,
} from 'stores/user';
import { delayNavigate, isVN, showData } from 'utils';
import { useDispatch } from 'stores';
import _, { isEmpty } from 'lodash';
import CheckinCard from 'components/card/CheckinCard';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { ROUTES, THEME_COLOR } from 'constant';
import { SecondaryLayout } from 'layouts';
import { useDirection } from 'hooks';

export function CheckinContainer() {
  const [isBlackCard, setIsBlackCard] = useState(false);
  usePWATemplateColor(isBlackCard ? THEME_COLOR.BLACK : THEME_COLOR.MAIN);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goTo } = useDirection();
  const { userProfile, userContracts, selectedContract } = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const initProfileAPI = async () => {
    if (_.isEmpty(userProfile)) {
      setIsLoadingProfile(true);
      await dispatch(getUserProfileAction());
      delayNavigate(() => setIsLoadingProfile(false));
    }
  };

  const initCallAPI = async () => {
    setIsLoading(true);
    if (isEmpty(userContracts)) {
      await dispatch(getUserContractsAction());
    }
    delayNavigate(() => setIsLoading(false));
  };

  const handleChangeCard = (cardIdx: number) => {
    if (userContracts && userContracts?.length > 0) {
      const contract = userContracts[cardIdx];
      dispatch(changeSelectedContract(contract));
    }
  };

  useEffect(() => {
    if (selectedContract) {
      setIsBlackCard(Boolean(selectedContract.paymentPlan?.isBlackCard));
    }
  }, [selectedContract]);

  useEffect(() => {
    initProfileAPI();
    initCallAPI();
  }, []);

  return (
    <SecondaryLayout
      isHideFooter
      isHideHome
      isHideBottomSpace
      isHideSlogan
      headerBackground={isBlackCard ? 'var(--black-color)' : 'var(--blue-bg-color)'}
      titleColor={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
      arrowColor={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
      backgroundColor={isBlackCard ? 'var(--black-color)' : 'var(--blue-bg-color)'}
      title={isBlackCard ? 'title.priority-pass' : 'title.club-pass'}
    >
      <div
        className={styles.containerWrapper}
        style={{
          backgroundImage: 'url(/images/support-background/sp-bg-01.png)',
        }}
      >
        <div className={`${styles.container} ${isBlackCard ? styles.blackCard : ''}`}>
          <p
            className="font-xxl font-bold"
            style={{
              color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
              textAlign: 'center',
            }}
          >
            {showData(userProfile?.fullName)}
          </p>
          <div className={styles.cardSwiper}>
            <CardSwiper
              activeIdx={userContracts?.findIndex(
                (contract) => contract.id === selectedContract?.id,
              )}
              items={userContracts?.map((contract, idx) => {
                return (
                  <CheckinCard
                    key={idx}
                    isBlackCard={isBlackCard}
                    isLoading={isLoading}
                    isLoadingProfile={isLoadingProfile}
                    planName={isVN() ? contract.paymentPlan?.nameVi : contract.paymentPlan?.nameEn}
                    pin={String(userProfile?.pin)}
                    expiredDate={contract?.endDate}
                  />
                );
              })}
              onChangeIdx={handleChangeCard}
            />
          </div>
          <div className={styles.note}>
            <p
              className="font-md color-white font-medium"
              style={{
                lineHeight: 'normal',
                color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
              }}
            >
              {t('paragraph.have-an-awesome-workout', { name: showData(userProfile?.lastName) })}
            </p>
            <p
              className="font-md font-medium"
              style={{
                color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
              }}
            >
              {t('paragraph.you-got-this')}
            </p>
          </div>
          <div className={styles.content}>
            <SecondaryButton
              text={t('title.refer-friend')}
              iconUrl="/images/icon/user-plus.svg"
              iconPathFill={isBlackCard ? 'var(--black-color)' : 'var(--white-text-color)'}
              style={{
                color: isBlackCard ? 'var(--black-color)' : 'var(--white-text-color)',
                width: '100%',
                maxWidth: '272px',
                margin: 'auto',
                background: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
              }}
              onClick={goTo(ROUTES.REFERRAL)}
            />
          </div>
        </div>
      </div>
    </SecondaryLayout>
  );
}
