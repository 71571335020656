import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { SvgIcon } from 'components/shared';
import { Divider, Skeleton } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import { E_CONTRACT_STATUS } from 'enum';
import { formatPrice, showData } from 'utils';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash';

type Props = {
  status?: E_CONTRACT_STATUS;
  clubName?: string;
  membershipName?: string;
  paymentDate?: string;
  transactionId?: string;
  duration?: string;
  membershipFee?: number;
  startupFee?: string;
  promoDiscount?: number;
  referralInfo?: string;
  totalPayment?: number;
  isSkeleton?: boolean;
};

function ContractDetailCard({
  status,
  clubName,
  membershipName,
  paymentDate,
  transactionId,
  duration,
  membershipFee,
  startupFee,
  promoDiscount,
  referralInfo,
  totalPayment,
  isSkeleton = false,
}: Props) {
  const { isOpen, onToggle } = useDisclosure(true);
  const { t } = useTranslation();

  const logo = useMemo(() => {
    switch (status) {
      case E_CONTRACT_STATUS.WAIT_FOR_PAYMENT:
        return '/images/icon/pending.svg';
      default:
        return '/images/icon/tick-circle.svg';
    }
  }, [status]);

  const statusTitle = useMemo(() => {
    switch (status) {
      case E_CONTRACT_STATUS.WAIT_FOR_PAYMENT:
        return 'title.pending-payment';
      default:
        return 'title.successful-payment';
    }
  }, [status]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.title}>
        <div className={styles.statusIcon}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: '100%',
                borderRadius: ' 100px',
              }}
            />
          ) : (
            <SvgIcon src={logo} />
          )}
        </div>
        <p className="color-dark-black">
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            t(statusTitle)
          )}
        </p>
      </div>

      <div
        className={`${styles.transactionInfo} ${isOpen ? styles.open : ''}`}
        style={{
          maxHeight: isOpen ? '600px' : '0px',
          overflow: 'hidden',
          transition: '0.2s linear',
        }}
      >
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.club')}</p>
              <p className={styles.blockValue}>{showData(clubName)}</p>
            </>
          )}
        </div>
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.membership')}</p>
              <p className={styles.blockValue}>{showData(membershipName)}</p>
            </>
          )}
        </div>
        {paymentDate && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.purchase-date')}</p>
                <p className={styles.blockValue}>{paymentDate}</p>
              </>
            )}
          </div>
        )}
        {transactionId && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.transaction-code')}</p>
                <p className={styles.blockValueSmall}>{transactionId}</p>
              </>
            )}
          </div>
        )}
        <Divider
          style={{
            margin: 0,
            borderColor: 'var(--secondary-text-color)',
            borderStyle: 'dashed',
          }}
        />
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{showData(duration)}</p>
              <p className={styles.blockValue}>{formatPrice(membershipFee || 0)}</p>
            </>
          )}
        </div>
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p className={styles.blockLabel}>{t('title.start-up-fee')}</p>
              <p className={styles.blockValue}>{showData(startupFee)}</p>
            </>
          )}
        </div>
        {!isNull(promoDiscount) && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.promo-code')}</p>
                <p
                  className={styles.blockValue}
                  style={{
                    color: 'var(--high-light-color)',
                  }}
                >
                  {!promoDiscount ? promoDiscount : `-${formatPrice(promoDiscount)}`}
                </p>
              </>
            )}
          </div>
        )}
        {referralInfo && (
          <div className={styles.blockContent}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-lg)',
                }}
              />
            ) : (
              <>
                <p className={styles.blockLabel}>{t('title.referral-code')}</p>
                <p
                  className={styles.blockValue}
                  style={{
                    color: 'var(--sub-high-light-color)',
                  }}
                >
                  {referralInfo}
                </p>
              </>
            )}
          </div>
        )}
        <div className={styles.blockContent}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <>
              <p
                className={styles.blockLabel}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('title.total')}
              </p>
              <p className={styles.blockValue}>{formatPrice(totalPayment || 0)}</p>
            </>
          )}
        </div>
      </div>
      <div className={styles.toggleTag} onClick={onToggle}>
        <SvgIcon
          className={styles.arrow}
          src="/images/icon/arrow-down.svg"
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
          }}
        />
        <SvgIcon className={styles.background} src="/images/icon/trapezoid.svg" />
      </div>
    </div>
  );
}

export default React.memo(ContractDetailCard);
