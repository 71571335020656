import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { ReactSVG } from 'react-svg';
import { formatDate, formatPrice, formatTime, showData } from 'utils';
import { E_CONTRACT_STATUS } from 'enum';
import { Skeleton } from 'antd-mobile';

type Props = {
  createdAt?: string;
  price?: number;
  status?: string;
  planName?: string;
  isSkeleton?: boolean;
};

function ContractHistoryCard({
  createdAt,
  status,
  price = 0,
  planName = '',
  isSkeleton = false,
}: Props) {
  const icon = useMemo(() => {
    switch (status) {
      case E_CONTRACT_STATUS.WAIT_FOR_PAYMENT:
        return '/images/icon/circle-menu.svg';
      case E_CONTRACT_STATUS.CURRENT:
        return '/images/icon/success.svg';
      case E_CONTRACT_STATUS.FUTURE:
        return '/images/icon/success.svg';
      default:
        return '/images/icon/success.svg';
    }
  }, [status]);

  return (
    <div className={styles.border}>
      <div className={styles.borderWrapper}>
        <div className={styles.cardWrapper}>
          <div className={styles.icon}>
            <ReactSVG src={icon} />
          </div>
          <div className={styles.information}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <>
                <p className="font-sm color-black">{showData(formatDate(createdAt))}</p>
                <p className="font-sm color-black">{showData(formatTime(createdAt))}</p>
              </>
            )}
          </div>

          <div className={styles.contract}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <>
                <p className="font-md color-black font-bold">{showData(planName)}</p>
                <p className="font-md color-blue font-bold">{formatPrice(price)}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContractHistoryCard);
