/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable lines-around-comment */
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PrimaryInput } from 'components/input';
import { PrimaryButton } from 'components';
import { useDirection, useFormData, useValidation } from 'hooks';
import { JOI, delayNavigate, isSuccessCode } from 'utils';
import { AuthService } from 'services';
import { ROUTES } from 'constant';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import { Image } from 'antd-mobile';

type FormData = {
  phoneNumber: string;
};

const initFormData: FormData = {
  phoneNumber: '',
};

export function SignUpContainer() {
  const authService = new AuthService();
  const { t } = useTranslation();
  const { isLoading } = useSelector(commonSelector);

  const { formData, KEY, onChangeForm } = useFormData<FormData>(initFormData);
  const { goTo } = useDirection();
  const { errorMessage, onValidate } = useValidation<FormData>({
    phoneNumber: JOI.PHONE_REQUIRE,
  });

  const handleCreateAccount = async () => {
    const isError = onValidate(formData);
    if (!isError) {
      const response = await authService.signUp(formData);
      const { code } = response;
      if (isSuccessCode(code)) {
        delayNavigate(
          goTo(ROUTES.VERIFY_OTP, {
            state: {
              phoneNumber: formData.phoneNumber,
            },
          }),
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <Image className={styles.logo} src="/images/logo/white-logo.png" width="150px" />
      <p className="font-5xl color-white font-medium">{t('title.hello')}</p>
      <div className={styles.inputWrapper}>
        <PrimaryInput
          placeholder={t('placeholder.your-phone-number')}
          type="tel"
          value={formData.phoneNumber}
          onChange={onChangeForm(KEY.PHONE_NUMBER)}
          errorMessage={errorMessage.phoneNumber}
        />
      </div>

      <div className={styles.btnGroup}>
        <PrimaryButton
          variant="high-light"
          text={t('button.sign-up')}
          isFullWidth={false}
          loading={isLoading}
          onClick={handleCreateAccount}
        />
      </div>
    </div>
  );
}
