import { CheckinContainer } from 'containers';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'stores';
import { getUserProfileAction, userSelector } from 'stores/user';

type Props = {};

export default function CheckInPage({}: Props) {
  const { userProfile } = useSelector(userSelector);
  const dispatch = useDispatch();

  const initCallAPI = async () => {
    if (isEmpty(userProfile)) {
      await dispatch(getUserProfileAction());
    }
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  return <CheckinContainer />;
}
