import { THEME_COLOR } from 'constant';
import { PTContractSignContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PTContractSignPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout title={'title.contract-sign'} isHideFooter isWhiteBg isHideBottomSpace>
      <PTContractSignContainer />
    </SecondaryLayout>
  );
}
