/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useDispatch } from 'stores';
import { useEffect, useState } from 'react';
import { getUserContractsAction, userSelector } from 'stores/user';
import { useDirection, useDisclosure } from 'hooks';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import {
  CustomTitle,
  HomeMembershipCard,
  HomePagePTSessionCard,
  SecondaryButton,
} from 'components';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { ROUTES } from 'constant';
import { UserService } from 'services';
import { BookingHistoryType, FreezeContractType } from 'types';
import _, { isEmpty } from 'lodash';
import FreezeService from 'services/freeze.service';
import ConfirmModal from 'components/modal/ConfirmModal';
import { setLoadingToast } from 'stores/common';
import MiniClassCard from 'components/card/MiniClassCard';
import CardHaftSwiper from 'components/shared/CardHaftSwiper';
import { Link } from 'react-router-dom';
import DiscoverCard from 'components/card/DiscoverCard';

type Props = {};

export function HomeContainer({}: Props) {
  const userService = new UserService();
  const freezeService = new FreezeService();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [isLoading, setIsLoading] = useState(false);
  const [freezeContract, setFreezeContract] = useState<FreezeContractType>({});
  const [currentBooking, setCurrentBooking] = useState<BookingHistoryType>({});
  const { selectedContract, userContracts } = useSelector(userSelector);

  const dispatch = useDispatch();

  const getCurrentFreezeContract = async () => {
    const response = await freezeService.getCurrentFreezeContract();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setFreezeContract(data);
    }
  };

  const getCurrentBooking = async () => {
    const response = await userService.getCurrentBooking();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setCurrentBooking(data);
    }
  };

  const handleUnfreeze = async () => {
    dispatch(setLoadingToast(true));
    const response = await freezeService.UnfreezeContract();
    const { code } = response;
    if (isSuccessCode(code)) {
      await getCurrentFreezeContract();
    }
    dispatch(setLoadingToast(false));
  };

  const initCallAPI = async () => {
    setIsLoading(true);
    if (isEmpty(userContracts)) {
      await dispatch(getUserContractsAction());
    }
    await getCurrentFreezeContract();
    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    initCallAPI();
    getCurrentBooking();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.overlay} />
        <div className={styles.content}>
          <p className={styles.title}>{t('paragraph.no-judgement')}</p>
          <SecondaryButton
            text={t('title.refer-friend')}
            variant="outline"
            iconUrl="/images/icon/user-plus.svg"
            style={{
              padding: '8px 16px',
              background: 'var(--blue-bg-color)',
              border: 'none',
              margin: 'auto',
              maxWidth: '188px',
              width: '100%',
            }}
            onClick={goTo(ROUTES.REFERRAL)}
          />
        </div>
      </div>
      <HomeMembershipCard
        clubName={isVN() ? selectedContract?.club?.nameVi : selectedContract?.club?.nameEn}
        isSkeleton={isLoading}
        isHaveMembership={!isEmpty(selectedContract)}
        isFreeze={!isEmpty(freezeContract)}
        endFreezeDate={freezeContract?.freezeEndDate}
        onCancel={onOpen}
        style={{
          transform: 'translateY(-50%)',
          margin: '0 var(--space-medium)',
        }}
      />
      <div className={styles.features}>
        {!_.isEmpty(currentBooking) && (
          <div className={styles.ptSession}>
            <HomePagePTSessionCard
              ptName={[
                currentBooking.pt?.accountDetail?.firstName,
                currentBooking.pt?.accountDetail?.lastName,
              ].join(' ')}
              startTime={currentBooking.startDate}
              endTime={currentBooking.endDate}
              onClick={goTo(ROUTES.PT_BOOKING)}
            />
          </div>
        )}
        <div className={styles.listAction}>
          <div className={styles.item} onClick={goTo(ROUTES.MEMBERSHIP)}>
            <ReactSVG src="/images/icon/star.svg" />
            <p className="font-us">{t('title.membership')}</p>
          </div>
          <div className={styles.item} onClick={goTo(ROUTES.PT)}>
            <ReactSVG src="/images/icon/person.svg" />
            <p className="font-us">{t('title.pt')}</p>
          </div>
          <div className={styles.item} onClick={goTo(ROUTES.CLASS)}>
            <ReactSVG src="/images/icon/calendar.svg" />
            <p className="font-us">{t('title.class')}</p>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <CustomTitle text={t('title.today-class')} fontSize="20px" />
        <p className="font-sm color-grey font-medium">{t('title.see-all')}</p>
      </div>
      <div className={styles.todayClass}>
        <CardHaftSwiper items={[<MiniClassCard />, <MiniClassCard />, <MiniClassCard />]} />
      </div>
      <div className={styles.content}>
        <CustomTitle text={t('title.workout-with-us')} fontSize="20px" />
        <p className="font-sm color-grey font-medium">{t('title.see-all')}</p>
      </div>
      <div className={styles.discover}>
        <DiscoverCard
          text={t('title.virtual-club-tour')}
          backgroundUrl="/images/background/bg10.png"
        />
      </div>
      <ConfirmModal
        confirmTitle={t('paragraph.sure-unfreeze')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleUnfreeze}
      />
    </div>
  );
}
