import { THEME_COLOR } from 'constant';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function TermsAndConditionPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout
      title={'title.term-condition'}
      isHideFooter
      isHideBottomSpace
      isWhiteBg
      isHideHome
    >
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 54px)',
        }}
      >
        <iframe width="100%" height="100%" src="https://thenewgym.vn/dieu-khoan-dieu-kien/" />
      </div>
    </SecondaryLayout>
  );
}
