/* eslint-disable @typescript-eslint/no-unused-vars */
import { SpaceProps } from 'antd-mobile';
import React, { useState } from 'react';
import './styles.scss';
import { Tooltip } from 'antd';
import { showData } from 'utils';
import SvgIcon from '../SVGIcon';
import { isMobile } from 'react-device-detect';

type Props = {
  children: React.ReactNode;
  tooltipTitle?: string;
  tooltipContent?: string;
};

export default function TooltipWrapper({
  children,
  tooltipTitle = '',
  tooltipContent = '',
  ...rest
}: Props & SpaceProps) {
  const [visible, setVisible] = useState(false);

  return (
    <Tooltip
      className="tooltip-wrapper"
      placement={isMobile ? undefined : 'left'}
      visible={visible}
      title={
        <div className="tooltip-content">
          <p className="color-dark-black font-bold font-lg">{showData(tooltipTitle)}</p>
          <p
            className="color-black font-light font-md"
            style={{
              lineHeight: 'normal',
              whiteSpace: 'pre-wrap',
            }}
          >
            {showData(tooltipContent)}
          </p>
          <SvgIcon
            className="close-btn"
            src="/images/icon/close.svg"
            pathFill="var(--secondary-text-color)"
            onClick={() => {
              setVisible(false);
            }}
          />
        </div>
      }
      onOpenChange={(value) => setVisible(value)}
      zIndex={1}
    >
      <div
        {...rest}
        style={{
          cursor: 'pointer',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}
