export const BLACK_CARD_BASIC_INFO = [
  {
    content: 'paragraph.pr15',
    iconPath: '/images/icon/money-square.svg',
    hint: {
      title: 'title.start-up-fee',
      content: 'paragraph.pr21',
    },
  },
  {
    content: 'paragraph.pr16',
    iconPath: '/images/icon/clock-1.svg',
    hint: {
      title: 'paragraph.pr16',
      content: 'paragraph.pr43',
    },
  },
  {
    content: 'paragraph.pr10',
    iconPath: '/images/icon/person-1.svg',
    hint: {
      title: 'paragraph.pr10',
      content: 'paragraph.pr44',
    },
  },
  {
    content: 'paragraph.pr17',
    iconPath: '/images/icon/clock-3.svg',
  },
];

export const BASIC_BASIC_INFO = [
  {
    content: 'paragraph.pr19',
    iconPath: '/images/icon/money-square.svg',
    hint: {
      title: 'title.start-up-fee',
      content: 'paragraph.pr21',
    },
  },
  {
    content: 'paragraph.pr16',
    iconPath: '/images/icon/clock-1.svg',
    hint: {
      title: 'paragraph.pr16',
      content: 'paragraph.pr43',
    },
  },
  {
    content: 'paragraph.pr20',
    iconPath: '/images/icon/calendar-check.svg',
    hint: {
      title: 'paragraph.pr20',
      content: 'paragraph.pr22',
    },
  },
  {
    content: 'paragraph.pr17',
    iconPath: '/images/icon/clock-3.svg',
  },
];

export const BLACK_CARD_MORE_INFO = [
  {
    icon: '/images/icon/home-outline.svg',
    content: 'paragraph.pr1',
  },
  {
    icon: '/images/icon/whistle.svg',
    content: 'paragraph.pr3',
  },
  {
    icon: '/images/icon/droplet.svg',
    content: 'paragraph.pr4',
  },
  {
    icon: '/images/icon/smartphone.svg',
    content: 'paragraph.pr5',
  },

  // {
  //   icon: '/images/icon/transfer.svg',
  //   content: 'paragraph.pr6',
  // },
  {
    icon: '/images/icon/freeze.svg',
    content: 'paragraph.pr7',
  },

  // {
  //   icon: '/images/icon/appointment.svg',
  //   content: 'paragraph.pr8',
  // },
  {
    icon: '/images/icon/teacher.svg',
    content: 'paragraph.pr9',
  },

  // {
  //   icon: '/images/icon/person-1.svg',
  //   content: 'paragraph.pr10',
  // },
  {
    icon: '/images/icon/meal.svg',
    content: 'paragraph.pr11',
  },
  {
    icon: '/images/icon/discount.svg',
    content: 'paragraph.pr12',
  },
];

export const BASIC_MORE_INFO = [
  {
    icon: '/images/icon/home-outline.svg',
    content: 'paragraph.pr1',
  },
  {
    icon: '/images/icon/whistle.svg',
    content: 'paragraph.pr3',
  },
  {
    icon: '/images/icon/droplet.svg',
    content: 'paragraph.pr4',
  },
  {
    icon: '/images/icon/smartphone.svg',
    content: 'paragraph.pr5',
  },
];
