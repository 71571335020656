import { APIResponse } from 'types';
import { AxiosGW } from './axios.service';
import { GetClassReq, GetPaymentPlanDetailReq, GetPaymentPlanReq } from 'types/global.type';

export default class GlobalService {
  async getPaymentPlans(payload: GetPaymentPlanReq) {
    const { clubId, coupon } = payload;

    return (
      await AxiosGW.get(`/api/v1/global/club/${clubId}/payment-plans`, {
        params: {
          coupon,
        },
      })
    ).data as APIResponse;
  }

  async getPaymentPlanDetail(payload: GetPaymentPlanDetailReq) {
    const { paymentPlanId, clubId } = payload;

    return (await AxiosGW.get(`/api/v1/global/club/${clubId}/payment-plan/${paymentPlanId}/detail`))
      .data as APIResponse;
  }

  async getPTPackages() {
    return (await AxiosGW.get('/api/v1/global/pt-packages')).data as APIResponse;
  }

  async getClasses(payload: GetClassReq) {
    return (await AxiosGW.get('/api/v1/global/classes', { params: payload })).data as APIResponse;
  }
}
