import { Navigate, RouteObject } from 'react-router-dom';
import {
  AccountPage,
  HomePage,
  MembershipPage,
  SignUpPage,
  PaymentAtPosPage,
  VNPayStatusPage,
  LoginPage,
  VerifyOTPPage,
  CheckInPage,
  SelectPlansPage,
  PaymentMembershipPage,
  PTPage,
  ChoosePTPage,
  PTPlanPage,
  PTBookingPage,
  PaymentFreezePage,
  TermsAndConditionPage,
  PrivacyPolicyPage,
  AboutPage,
  MyInformationPage,
  VerifyIdentityCardPage,
  VerifyIdentitySuccessPage,
  ClassPage,
  ClassDetailPage,
  PTContractPage,
  ViewPTContractPage,
  PTContractSignPage,
  LandingPage,
  ReferralPage,
  PaymentResultPage,
  ClubDetailPage,
} from 'pages';
import { BlankLayout } from 'layouts';
import { ROUTES } from 'constant';
import PTPaymentPage from 'pages/pt/choose/PTPaymentPage';

const Router: RouteObject[] = [
  /** HOME */
  {
    path: '/',
    children: [
      { path: '/', element: <Navigate to={ROUTES.HOME} /> },
      { path: ROUTES.HOME, element: <HomePage /> },
      { path: ROUTES.CHECK_IN, element: <CheckInPage /> },
      { path: ROUTES.REFERRAL, element: <ReferralPage /> },
    ],
  },

  /** ACCOUNT */
  {
    path: '/',
    children: [
      { path: ROUTES.ACCOUNT, element: <AccountPage /> },
      { path: ROUTES.ACCOUNT_TERM_CONDITION, element: <TermsAndConditionPage /> },
      { path: ROUTES.ACCOUNT_PRIVACY, element: <PrivacyPolicyPage /> },
      { path: ROUTES.ACCOUNT_ABOUT, element: <AboutPage /> },
      { path: ROUTES.ACCOUNT_INFORMATION, element: <MyInformationPage /> },
      { path: ROUTES.ACCOUNT_VERIFICATION, element: <VerifyIdentityCardPage /> },
      { path: ROUTES.ACCOUNT_VERIFICATION_SUCCESS, element: <VerifyIdentitySuccessPage /> },
    ],
  },

  // /** MEMBERSHIP */
  {
    path: '/',
    children: [
      {
        path: ROUTES.MEMBERSHIP,
        element: <MembershipPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_SELECT,
        element: <SelectPlansPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_PAYMENT,
        element: <PaymentMembershipPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_PAYMENT_RESULT,
        element: <PaymentResultPage />,
      },
      {
        path: ROUTES.MEMBERSHIP_FREEZE_PAYMENT,
        element: <PaymentFreezePage />,
      },
      {
        path: ROUTES.MEMBERSHIP_CLUB_DETAIL,
        element: <ClubDetailPage />,
      },
    ],
  },

  /** PT */
  {
    path: '/',
    children: [
      { path: ROUTES.PT, element: <PTPage />, children: [] },
      { path: ROUTES.PT_SELECT, element: <ChoosePTPage /> },
      { path: ROUTES.PT_PLAN, element: <PTPlanPage /> },
      { path: ROUTES.PT_PAYMENT_PLAN, element: <PTPaymentPage /> },
      { path: ROUTES.PT_BOOKING, element: <PTBookingPage /> },
      { path: ROUTES.PT_CONTRACT, element: <PTContractPage /> },
      { path: ROUTES.PT_CONTRACT_PREVIEW_PDF, element: <ViewPTContractPage /> },
      { path: ROUTES.PT_CONTRACT_SIGN_PDF, element: <PTContractSignPage /> },
    ],
  },

  /** CLASS */
  {
    path: '/',
    children: [
      {
        path: ROUTES.CLASS,
        element: <ClassPage />,
      },
      {
        path: ROUTES.CLASS_DETAIL,
        element: <ClassDetailPage />,
      },
    ],
  },

  /** STATUS */
  {
    path: '/',
    children: [
      { path: ROUTES.STATUS_PAYMENT_POS, element: <PaymentAtPosPage /> },
      { path: ROUTES.STATUS_PAYMENT_VNP, element: <VNPayStatusPage /> },
    ],
  },

  /** AUTH */

  // {
  //   path: '/',
  //   element: <BlankLayout />,
  //   children: [{ path: ROUTES.LADING, element: <LandingPage /> }],
  // },

  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: ROUTES.LADING, element: <LandingPage /> },
      { path: ROUTES.LOGIN, element: <LoginPage /> },
      { path: ROUTES.VERIFY_OTP, element: <VerifyOTPPage /> },
      { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
    ],
  },
];

export default Router;
