import styles from './styles.module.scss';
import { Divider, Skeleton } from 'antd-mobile';
import { CustomTitle, PaymentMethodRadio } from 'components';
import ConfirmModal from 'components/modal/ConfirmModal';
import { ROUTES } from 'constant';
import { PAYMENT_METHOD } from 'enum';
import { useDirection, useDisclosure } from 'hooks';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { UserService } from 'services';
import { useDispatch, useSelector } from 'stores';
import { generalSelector, getPaymentMethodAction } from 'stores/general';
import { CreatePTContractReq, TransactionPTType } from 'types';
import { delayNavigate, formatDate, formatPrice, isSuccessCode, isVN, showData } from 'utils';

type Props = {};

export function PTPaymentContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { paymentMethods } = useSelector(generalSelector);
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const userService = new UserService();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMethod, setIsLoadingMethod] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState<TransactionPTType>();

  const getPTContractDetail = async () => {
    setIsLoading(true);
    const response = await userService.getRegisteredPTContract({
      ptContractId: String(contractId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setTransactionDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getPaymentMethods = async () => {
    if (_.isEmpty(paymentMethods)) {
      setIsLoadingMethod(true);
      await dispatch(getPaymentMethodAction());
      setIsLoadingMethod(false);
    }
  };

  const onChangeMethod = (key: string | number) => {
    setSelectedPaymentMethodId(String(key));
  };

  const depositedAmount = useMemo(() => {
    return (
      Number(transactionDetail?.paymentTotal) -
      Number(transactionDetail?.discount) -
      Number(transactionDetail?.transactionAmount)
    );
  }, [transactionDetail]);

  const createPTContract = async () => {
    const reqData: CreatePTContractReq = {
      paymentMethodGroupId: selectedPaymentMethodId,
      ptContractId: String(contractId),
    };
    setIsLoadingButton(true);
    const response = await userService.createPTContract(reqData);
    const { code, data } = response;
    if (isSuccessCode(code)) {
      delayNavigate(() => {
        setIsLoadingButton(false);
        if (selectedPaymentMethodId == PAYMENT_METHOD.ONLINE) {
          window.open(data?.paymentLinkUrl);
        } else {
          goTo(ROUTES.STATUS_PAYMENT_POS)();
        }
      });
    } else {
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      setSelectedPaymentMethodId(String(paymentMethods[0].id));
    }
  }, [paymentMethods]);

  useEffect(() => {
    getPaymentMethods();
    getPTContractDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <CustomTitle text={t('title.payment-method')} />
      <div className={`${styles.item}`}>
        <PaymentMethodRadio
          value={selectedPaymentMethodId}
          options={paymentMethods?.map((method) => ({
            title:
              String(method.id) === PAYMENT_METHOD.ONLINE ? t('title.online') : t('title.cashier'),
            value: String(method.id),
            url:
              String(method.id) === PAYMENT_METHOD.ONLINE
                ? '/images/icon/mobile-dollar.svg'
                : '/images/icon/cashier.svg',
          }))}
          isSkeleton={isLoadingMethod}
          onSelect={onChangeMethod}
        />
      </div>
      <CustomTitle text={t('title.order-summary')} />
      <div className={`${styles.summary} ${styles.item}`}>
        <div className={styles.summaryItem}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className=" font-sm color-black">
              {showData(
                isVN()
                  ? transactionDetail?.ptContract.ptPackage?.nameVi
                  : transactionDetail?.ptContract.ptPackage?.nameEn,
              )}
            </p>
          )}
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <p className=" font-md color-black font-bold">
              {showData(formatPrice(Number(transactionDetail?.paymentTotal)))}
            </p>
          )}
        </div>
        <div className={styles.summaryItem}>
          <p className=" font-sm color-black">{t('title.deposited-amount')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <p className=" font-md color-orange font-bold">
              {depositedAmount > 0
                ? `-${formatPrice(depositedAmount)}`
                : formatPrice(depositedAmount)}
            </p>
          )}
        </div>
        <Divider
          style={{
            borderColor: '#00EBCD',
            margin: '0',
          }}
        />
        <div className={styles.summaryItem}>
          <p className=" font-sm color-black">{t('title.discount')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <p className=" font-md color-orange font-bold">
              {Number(transactionDetail?.discount) > 0
                ? `-${formatPrice(Number(transactionDetail?.discount))}`
                : formatPrice(Number(transactionDetail?.discount))}
            </p>
          )}
        </div>
        <Divider
          style={{
            borderColor: '#00EBCD',
            margin: '0',
          }}
        />
        <div className={styles.summaryItem}>
          <p className=" font-sm color-black">{t('title.total')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <p className=" font-md color-green font-bold">
              {showData(formatPrice(Number(transactionDetail?.transactionAmount)))}
            </p>
          )}
        </div>
        <div className={styles.summaryItem}>
          <p className=" font-sm color-black">{t('title.start-date')}</p>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '100px',
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className=" font-sm color-black">
              {showData(formatDate(transactionDetail?.ptContract.startDate))}
            </p>
          )}
        </div>
      </div>
      {!isLoading && (
        <div
          className={styles.payNow}
          onClick={onOpen}
          style={{
            pointerEvents: isLoadingButton ? 'none' : 'auto',
          }}
        >
          <p className=" font-xl color-blue font-bold">{t('title.pay-now')}</p>
          <p className=" font-lg color-black font-bold">
            {showData(formatPrice(Number(transactionDetail?.transactionAmount)))}
          </p>
        </div>
      )}
      <ConfirmModal
        confirmTitle={t('paragraph.sure-payment')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={createPTContract}
      />
    </div>
  );
}
