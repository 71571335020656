import { THEME_COLOR } from 'constant';
import { useEffect } from 'react';

function usePWATemplateColor(color: string) {
  useEffect(() => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);

    return () => {
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', THEME_COLOR.MAIN);
    };
  }, [color]);
}

export default usePWATemplateColor;
