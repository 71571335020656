import React from 'react';
import styles from './styles.module.scss';
import { CapsuleTabs, Skeleton } from 'antd-mobile';

type Props = {
  activeChipKey?: string;
  chipList?: {
    title: string;
    key: string;
  }[];
  isSkeleton?: boolean;
  onChangeChip?: (key: string) => void;
};

function ChipSelector({ activeChipKey, chipList = [], isSkeleton = false, onChangeChip }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.chipList}>
        {isSkeleton ? (
          <Skeleton animated style={{ height: '30px', width: '100%', margin: '12px 0' }} />
        ) : (
          <CapsuleTabs
            className={styles.chipListWrapper}
            activeKey={activeChipKey}
            onChange={onChangeChip}
          >
            {chipList.map((chip) => {
              return (
                <CapsuleTabs.Tab key={chip.key} className={styles.chipItem} title={chip.title} />
              );
            })}
          </CapsuleTabs>
        )}
      </div>
    </div>
  );
}

export default React.memo(ChipSelector);
