import { useDirection, useDisclosure } from 'hooks';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constant';
import { useDispatch, useSelector } from 'stores';
import { generalSelector, getFreezePriceAction } from 'stores/general';
import { useEffect, useState } from 'react';
import { delayNavigate, formatDate, formatPrice, isSuccessCode, showData } from 'utils';
import { Skeleton } from 'antd-mobile';
import { FreezeContractType } from 'types';
import FreezeService from 'services/freeze.service';
import { isEmpty } from 'lodash';
import ConfirmModal from 'components/modal/ConfirmModal';
import { setLoadingToast } from 'stores/common';

type Props = {};

export function FreezeTab({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const freezeService = new FreezeService();
  const { freezePriceConfig } = useSelector(generalSelector);

  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [freezeContract, setFreezeContract] = useState<FreezeContractType>({});
  const dispatch = useDispatch();

  const getFreezePriceConfig = async () => {
    setIsLoadingPrice(true);
    await dispatch(getFreezePriceAction());
    delayNavigate(() => setIsLoadingPrice(false));
  };

  const getCurrentFreezeContract = async () => {
    const response = await freezeService.getCurrentFreezeContract();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setFreezeContract(data);
    }
  };

  const handleUnfreeze = async () => {
    dispatch(setLoadingToast(true));
    const response = await freezeService.UnfreezeContract();
    const { code } = response;
    if (isSuccessCode(code)) {
      await getCurrentFreezeContract();
    }
    dispatch(setLoadingToast(false));
  };

  useEffect(() => {
    getFreezePriceConfig();
    getCurrentFreezeContract();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {isEmpty(freezeContract) ? (
          <p
            className="font-xl color-blue font-bold"
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              lineHeight: '24px',
            }}
          >
            {t('paragraph.to-busy')}
          </p>
        ) : (
          <p
            className="font-sm color-grey"
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              lineHeight: '24px',
            }}
          >
            {t('title.freeze-until', { date: formatDate(freezeContract.freezeEndDate) })}
          </p>
        )}
      </div>
      {isEmpty(freezeContract) ? (
        <div className={styles.freezeNow} onClick={goTo(ROUTES.MEMBERSHIP_FREEZE_PAYMENT)}>
          <p className="font-xl color-blue font-bold">{t('title.freeze-now')}</p>
          {isLoadingPrice ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '35%',
              }}
            />
          ) : (
            <p className="font-lg color-black font-bold">
              {showData(formatPrice(Number(freezePriceConfig?.settingValueEn?.value)))}
            </p>
          )}
        </div>
      ) : (
        <div
          className={styles.freezeNow}
          onClick={onOpen}
          style={{
            justifyContent: 'center',
          }}
        >
          <p className="font-xl color-blue font-bold">{t('title.cancel-freeze')}</p>
        </div>
      )}

      <ConfirmModal
        confirmTitle={t('paragraph.sure-unfreeze')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleUnfreeze}
      />
    </div>
  );
}
