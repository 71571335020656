import { createAsyncThunk } from '@reduxjs/toolkit';
import { E_CONTRACT_STATUS } from 'enum';
import { AuthService } from 'services';
import { UserService } from 'services';

export const getUserProfileAction = createAsyncThunk('user/getUserProfileAction', async () => {
  const authService = new AuthService();
  const response = await authService.getProfile();

  return response;
});

export const getUserContractsAction = createAsyncThunk('user/getUserContractsAction', async () => {
  const userService = new UserService();
  const response = await userService.getContracts({
    status: E_CONTRACT_STATUS.CURRENT,
  });

  return response;
});
