/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePickerView, FloatingPanel } from 'antd-mobile';
import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'components/button';
import { showData } from 'utils';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  value?: Date;
  note?: string;
  onChange?: (newDate?: Date) => void;
  onConfirm?: () => void;
};

type FloatingPanelRef = {
  setHeight: (
    height: number,
    options?: {
      immediate?: boolean; // whether to skip animation
    },
  ) => void;
};

function DatePicker({
  value,
  onChange,
  isOpen,
  isLoading = false,
  note = '',
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation();
  const refPanel = useRef<FloatingPanelRef>(null);
  const newRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target) && !isLoading) {
      onClose && onClose();
    }
  };

  const renderLabel = (type: string, data: number) => {
    switch (type) {
      case 'year':
        return data;
      case 'month':
        return String(data).padStart(2, '0');
      case 'day':
        return String(data).padStart(2, '0');

      default:
        return data;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    if (refPanel.current) {
      if (isOpen) {
        refPanel.current.setHeight(350);
      } else {
        refPanel.current.setHeight(0);
      }
    }
  }, [isOpen]);

  return createPortal(
    <div ref={newRef}>
      <FloatingPanel
        ref={refPanel}
        anchors={[0, 350]}
        className={styles.panel}
        handleDraggingOfContent={false}
      >
        {note && (
          <p
            className="font-xs color-grey"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(note)}
          </p>
        )}
        <div className={styles.labelGroup}>
          <p className="font-xl color-black">{t('title.year')}</p>
          <p className="font-xl color-black">{t('title.month')}</p>
          <p className="font-xl color-black">{t('title.day')}</p>
        </div>
        <DatePickerView
          min={new Date()}
          renderLabel={renderLabel}
          value={value}
          onChange={onChange}
        />
        <SecondaryButton
          text={t('button.confirm')}
          variant="high-light"
          style={{
            position: 'absolute',
            bottom: '24px',
            width: 'calc(100% - 24px)',
          }}
          loading={isLoading}
          onClick={onConfirm}
        />
      </FloatingPanel>
    </div>,
    document.body,
  );
}

export default React.memo(DatePicker);
