import PTContractHistoryCard from 'components/card/PTContractHistoryCard';
import styles from './styles.module.scss';
import { E_CONTRACT_STATUS } from 'enum';

type Props = {};

export default function ContractTab({}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <PTContractHistoryCard
          planName="Platinum"
          createdAt={new Date().toString()}
          price={12000000}
          status={E_CONTRACT_STATUS.WAIT_FOR_PAYMENT}
        />
      </div>
    </div>
  );
}
