import { APIResponse } from 'types';
import { AxiosGW } from './axios.service';
import { GetClubDetailReq, GetClubReq } from 'types/general.type';

export default class GeneralService {
  async getCityHaveClub() {
    return (await AxiosGW.get('/api/v1/general/city/have-clubs')).data as APIResponse;
  }

  async getClubInCity(payload: GetClubReq) {
    const { cityId } = payload;

    return (
      await AxiosGW.get('/api/v1/general/clubs', {
        params: { cityId, isActive: true },
      })
    ).data as APIResponse;
  }

  async getClubDetail(payload: GetClubDetailReq) {
    const { clubId } = payload;

    return (
      await AxiosGW.get(`/api/v1/general/club/${clubId}/detail`, {
        params: { clubId },
      })
    ).data as APIResponse;
  }

  async getPaymentMethods() {
    return (await AxiosGW.get('/api/v1/general/payment-method/groups')).data as APIResponse;
  }

  async getFreezePrice() {
    return (await AxiosGW.get('/api/v1/general/freeze-price')).data as APIResponse;
  }

  async getFreezeDuration() {
    return (await AxiosGW.get('/api/v1/general/freeze-duration')).data as APIResponse;
  }
}
