import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PrimaryButton } from 'components';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import SvgIcon from 'components/shared/SVGIcon';

type Props = {};

export function VerifyIdentitySuccessContainer({}: Props) {
  const { goTo } = useDirection();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.iconWrapper}>
          <SvgIcon src="/images/icon/tick-circle.svg" width="60px" height="60px" />
        </div>
        <p
          className="font-md font-medium"
          style={{
            textAlign: 'center',
            lineHeight: 'normal',
            color: 'var(--black-color)',
            whiteSpace: 'pre-wrap',
          }}
        >
          {t('paragraph.verify-id-success')}
        </p>
      </div>
      <div className={styles.bottomButton}>
        <PrimaryButton
          text={t('button.go-to-home')}
          variant="solid"
          style={{
            width: '100%',
          }}
          onClick={goTo(ROUTES.HOME, {
            replace: true,
          })}
        />
      </div>
    </div>
  );
}
