import { Toast } from 'antd-mobile';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { dispatch, useSelector } from 'stores';
import { commonSelector, setLoadingToast } from 'stores/common';

type Props = {
  isOpen?: boolean;
};

function ToastOverlay({ isOpen = false }: Props) {
  const { t } = useTranslation();
  const { isShowToast } = useSelector(commonSelector);

  useEffect(() => {
    if (isOpen) {
      Toast.show({
        maskClassName: styles.toastWrapper,
        icon: 'loading',
        duration: 0,
        content: `${t('button.processing')}...`,
        position: 'center',
        afterClose: () => {
          dispatch(setLoadingToast(false));
        },
      });
    } else {
      if (!isShowToast) {
        Toast.clear();
      }
    }
  }, [isOpen, isShowToast]);

  return <></>;
}

export default React.memo(ToastOverlay);
