import { getPTPackagesAction } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIResponse } from 'types';
import { PTPackageType } from 'types/global.type';
import { isSuccessCode } from 'utils';

interface State {
  ptPackages?: PTPackageType[];
}

const initialState: State = {
  ptPackages: [],
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPTPackagesAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.ptPackages = action.payload.data;
      }
    });
  },
});

export const {} = globalSlice.actions;
export default globalSlice.reducer;
