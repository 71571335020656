import { showData } from 'utils/common';
import { ActionSheet } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/action-sheet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  actionTitle?: string;
  actions: Action[];
};

function ActionPanel({ isOpen = false, onClose, actions = [], actionTitle = '' }: Props) {
  const { t } = useTranslation();

  return (
    <ActionSheet
      className={styles.actionSheet}
      visible={isOpen}
      extra={showData(actionTitle)}
      cancelText={
        <SecondaryButton text={t('button.close')} variant="high-light" style={{ width: '100%' }} />
      }
      safeArea={true}
      styles={{
        mask: {
          background: 'transparent',
        },
        body: {
          boxShadow: 'var(--shadow-03)',
          borderTopLeftRadius: '32px',
          borderTopRightRadius: '32px',
        },
      }}
      actions={actions}
      onClose={onClose}
    />
  );
}

export default React.memo(ActionPanel);
