import React from 'react';
import styles from './styles.module.scss';
import { Image, Input, InputProps } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

type Props = {
  url?: string;
  errorMessage?: string;
  rightSlot?: React.ReactNode;
};

function IconInput({ url, errorMessage = '', rightSlot, ...rest }: Props & InputProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.planBlock}>
        <div className={styles.blockItem}>
          <div className={styles.icon}>
            <Image src={url} width="25px" height="25px" />
          </div>
          <Input className={styles.input} type="tel" {...rest} />
          {rightSlot}
        </div>
      </div>
      {errorMessage && (
        <p
          className="error-message"
          style={{
            fontSize: 'var(--font-xs)',
            marginTop: 'var(--space-ultra-small)',
          }}
        >
          {t(`validate-message.${errorMessage}`)}
        </p>
      )}
    </div>
  );
}

export default React.memo(IconInput);
