import React from 'react';
import styles from './styles.module.scss';

type Props = {};

function MiniClassCard({}: Props) {
  return (
    <div className={styles.cardWrapper}>
      <img alt="" src="/images/background/bg9.jpeg" />
    </div>
  );
}

export default React.memo(MiniClassCard);
