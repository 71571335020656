/* eslint-disable @typescript-eslint/no-unused-vars */
import { Image } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

function InstallOverlay() {
  const { t } = useTranslation();

  return createPortal(
    <div className={styles.container}>
      <div className={styles.logo}>
        <Image
          src="/images/logo/white-logo.png"
          alt="logo"
          style={{
            width: '200px',
            height: 'auto',
          }}
        />

        <p className={`font-xxl color-white font-medium ${styles.animation}`}>
          {t('paragraph.slogan')}
        </p>
      </div>
    </div>,
    document.body,
  );
}

export default React.memo(InstallOverlay);
