export const ROUTES = {
  HOME: '/home',
  CHECK_IN: '/home/check-in',
  REFERRAL: '/home/referral',

  MEMBERSHIP: '/membership',
  MEMBERSHIP_CLUB_DETAIL: '/membership/:clubId/detail',
  MEMBERSHIP_SELECT: '/membership/:clubId',
  MEMBERSHIP_PAYMENT: '/membership/:clubId/plan/:paymentPlanId',
  MEMBERSHIP_PAYMENT_RESULT: '/membership/payment/result/:contractId',
  MEMBERSHIP_TRANSACTION_DETAIL: '/membership/transaction/:transactionId',
  MEMBERSHIP_FREEZE_PAYMENT: '/membership/freeze/payment',

  PT: '/pt',
  PT_SELECT: '/pt/select-pt',
  PT_PLAN: '/pt/select-pt/plans/:ptId',
  PT_PAYMENT_PLAN: '/pt/select-pt/plans/:ptId/plan/:contractId',
  PT_BOOKING: '/pt/booking',
  PT_CONTRACT: '/pt/contract',
  PT_CONTRACT_PREVIEW_PDF: '/pt/contract/:eContractId/preview',
  PT_CONTRACT_SIGN_PDF: '/pt/contract/:eContractId/preview/sign',

  CLASS: '/class',
  CLASS_DETAIL: '/class/:classId',

  TRAINING: '/training',
  TRAINING_PT_PLAN: '/training/plans/:ptId',
  TRAINING_PAYMENT_PT_PLAN: '/training/plans/:ptId/plan/:contractId',
  TRAINING_SCHEDULE: '/training/schedule',
  TRAINING_CONTRACT: '/training/contract',

  CLASSES: '/classes',
  ACTIVITY: '/activity',

  ACCOUNT: '/account',
  ACCOUNT_TERM_CONDITION: '/account/terms-condition',
  ACCOUNT_PRIVACY: '/account/privacy',
  ACCOUNT_ABOUT: '/account/about',
  ACCOUNT_INFORMATION: '/account/information',
  ACCOUNT_VERIFICATION: '/account/verification',
  ACCOUNT_VERIFICATION_SUCCESS: '/account/verification/success',

  LOGIN: '/login',
  VERIFY_OTP: '/verify-otp',
  SIGN_UP: '/sign-up',
  LADING: '/landing',

  STATUS_PAYMENT_SUCCESS: '/status/payment-success',
  STATUS_PAYMENT_FAIL: '/status/payment-fail',
  STATUS_PAYMENT_POS: '/status/payment-at-pos',
  STATUS_PAYMENT_VNP: '/status/vnpay',
};
