/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Outlet } from 'react-router';
import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.scss';
import { BottomSpace, PullToReload } from 'components';

type Props = {
  isHideFooter?: boolean;
  children?: React.ReactNode;
};

function MainLayout({ isHideFooter = false, children }: Props) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100);
    setScrollPosition(position);
  };

  return (
    <div className={styles.wrapperLayout}>
      <Header
        style={{
          background: scrollPosition <= 40 ? 'transparent' : 'rgba(0,0,0,0.3)',
        }}
      />
      <div id="main-body" className={styles.body} onScroll={handleScroll}>
        <PullToReload>
          {children}
          <BottomSpace />
        </PullToReload>
      </div>
      {!isHideFooter && <Footer />}
    </div>
  );
}

export default React.memo(MainLayout);
