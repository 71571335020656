import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { Skeleton, SpaceProps } from 'antd-mobile';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { formatDate, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  clubName?: string;
  isSkeleton?: boolean;
  isFreeze?: boolean;
  isHaveMembership?: boolean;
  endFreezeDate?: string;
  onCancel?: () => void;
};

function HomeMembershipCard({
  clubName,
  isSkeleton = false,
  isHaveMembership = false,
  isFreeze = true,
  endFreezeDate,
  onCancel,
  ...rest
}: Props & SpaceProps) {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  return (
    <div className={styles.wrapper} {...rest}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: 'var(--font-xl)',
          }}
        />
      ) : (
        <p
          className="font-xl font-bold color-black"
          style={{
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
          }}
        >
          {isHaveMembership ? showData(clubName) : t('title.join-tng-now')}
        </p>
      )}
      {isFreeze && (
        <p className="font-md color-black">
          {t('title.freeze-until', { date: formatDate(endFreezeDate) })}
        </p>
      )}
      {isFreeze ? (
        <SecondaryButton
          disabled={isSkeleton}
          text={t('title.cancel-freeze')}
          variant="high-light"
          iconUrl="/images/icon/unfreeze.svg"
          style={{
            width: '100%',
            background: 'var(--primary-text-color)',
          }}
          onClick={onCancel}
        />
      ) : isHaveMembership ? (
        <SecondaryButton
          disabled={isSkeleton}
          text="Check-in"
          variant="high-light-blue"
          iconUrl="/images/icon/scan.svg"
          style={{
            width: '100%',
            maxWidth: '269px',
          }}
          onClick={goTo(ROUTES.CHECK_IN)}
        />
      ) : (
        <SecondaryButton
          disabled={isSkeleton}
          text={t('title.join-now')}
          variant="high-light-blue"
          iconUrl="/images/icon/open-door.svg"
          style={{
            width: '100%',
            maxWidth: '269px',
          }}
          onClick={goTo(ROUTES.MEMBERSHIP)}
        />
      )}
    </div>
  );
}

export default React.memo(HomeMembershipCard);
