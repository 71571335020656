import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Camera } from 'react-camera-pro';
import { BottomSpace, PrimaryButton, TabWrapper } from 'components';
import { Divider, Image, SpinLoading } from 'antd-mobile';
import { LuSwitchCamera } from 'react-icons/lu';
import { isEmpty, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AuthService, UserService } from 'services';
import { dataURLtoFile, delayNavigate, formatDate, isSuccessCode, showData } from 'utils';
import { RECOGNIZE_TYPE } from 'enum';
import { IdCardInfo } from 'types';
import { useDispatch, useSelector } from 'stores';
import { setLoadingToast } from 'stores/common';
import { useDirection, useDisclosure } from 'hooks';
import { ROUTES } from 'constant';
import { getUserProfileAction, userSelector } from 'stores/user';
import ConfirmModal from 'components/modal/ConfirmModal';

type Props = {};

const TABS = {
  CCCD: RECOGNIZE_TYPE.ID_CARD,
  PASSPORT: RECOGNIZE_TYPE.PASSPORT,
};

export function VerifyIdentityCardContainer({}: Props) {
  const userService = new UserService();
  const authService = new AuthService();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { userProfile } = useSelector(userSelector);
  const { t } = useTranslation();
  const { goTo, goBack } = useDirection();
  const camera = useRef<any>(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState('');
  const [isCameraLoaded, setIsCameraLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(TABS.CCCD);
  const [cardInfo, setCardInfo] = useState<IdCardInfo | null>(null);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isScanSuccess, setIsScanSuccess] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const TAB_ITEMS = [
    {
      key: TABS.CCCD,
      title: 'CCCD/CMND',
    },
    {
      key: TABS.PASSPORT,
      title: 'Passport',
    },
  ];

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(String(key));
  };

  const handleCaptureImage = async () => {
    const photo = camera.current.takePhoto();
    setImage(photo);

    dispatch(setLoadingToast(true));
    setIsLoadingVerify(true);
    const response = await userService.verifyIdCard(dataURLtoFile(photo, 'temp'), selectedTab);
    const { data, code } = response;
    setIsScanSuccess(isSuccessCode(code));
    if (isSuccessCode(code)) {
      setCardInfo(data);
    }
    setIsLoadingVerify(false);
    delayNavigate(() => dispatch(setLoadingToast(false)));
  };

  const handleSwitchCamera = () => {
    camera.current.switchCamera();
  };

  const handleLoadedCamera = () => {
    setIsCameraLoaded(true);
  };

  const handleReCapture = () => {
    setImage('');
    setCardInfo(null);
  };

  const handleConfirm = async () => {
    if (!isEmpty(cardInfo)) {
      setIsLoadingConfirm(true);
      const response = await authService.confirmProfile(cardInfo);
      const { code } = response;
      if (isSuccessCode(code)) {
        await dispatch(getUserProfileAction());
        delayNavigate(
          goTo(ROUTES.ACCOUNT_VERIFICATION_SUCCESS, {
            replace: true,
          }),
        );
      }

      delayNavigate(() => setIsLoadingConfirm(false));
    }
  };

  const initCallAPI = async () => {
    if (isEmpty(userProfile)) {
      setIsLoading(true);
      await dispatch(getUserProfileAction());
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  useEffect(() => {
    if (userProfile?.idCardUploaded)
      goTo(ROUTES.ACCOUNT_VERIFICATION_SUCCESS, {
        replace: true,
      })();
  }, [userProfile]);

  return !isLoading ? (
    <div className={styles.wrapper}>
      {isEmpty(image) ? (
        <>
          <TabWrapper activeKey={selectedTab} items={TAB_ITEMS} onChangeTab={handleChangeTab} />
          <div className={styles.cameraWrapper} onClick={handleSwitchCamera}>
            <Camera
              ref={camera}
              aspectRatio={16 / 9}
              facingMode="environment"
              errorMessages={{
                noCameraAccessible: undefined,
                permissionDenied: undefined,
                switchCamera: undefined,
                canvas: undefined,
              }}
              videoReadyCallback={handleLoadedCamera}
            />
            <LuSwitchCamera
              className={styles.cameraSwitch}
              fontSize="32px"
              color="var(--white-text-color)"
            />
            {!isCameraLoaded && (
              <SpinLoading
                className={styles.spinner}
                style={{
                  width: '50px',
                  height: '50px',
                }}
              />
            )}
          </div>
          <p
            className="color-orange font-sm"
            style={{
              textAlign: 'center',
              marginTop: '12px',
              whiteSpace: 'pre-wrap',
              lineHeight: '20px',
            }}
          >
            {t('paragraph.take-picture-warning', {
              slot: keyBy(TAB_ITEMS, 'key')[selectedTab].title,
            })}
          </p>
        </>
      ) : (
        <Image className={styles.cameraWrapper} src={image} />
      )}
      {!isEmpty(image) && !isLoadingVerify && isScanSuccess && (
        <div className={styles.cardInfo}>
          <div className={styles.content}>
            <div className={styles.formItem}>
              <p className="font-xs color-grey">{t('title.full-name')}</p>

              <p className="font-sm color-black">{showData(cardInfo?.fullName)}</p>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.wrapperInfo}>
              <div className={styles.formItem}>
                <p className="font-xs color-grey">{t('title.birthday')}</p>

                <p className="font-sm color-black">{showData(formatDate(cardInfo?.birthday))}</p>
              </div>
              <div className={styles.formItem}>
                <p className="font-xs color-grey">{t('title.gender')}</p>

                <p className="font-sm color-black">
                  {cardInfo?.gender == '0' ? t('title.female') : t('title.male')}
                </p>
              </div>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.formItem}>
              <p className="font-xs color-grey">CMND/CCCD/Passport</p>

              <p className="font-sm color-black">
                {showData(cardInfo?.cardNumber || cardInfo?.passportNumber)}
              </p>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.formItem}>
              <p className="font-xs color-grey">{t('title.address')}</p>

              <p className="font-sm color-black">{showData(cardInfo?.address)}</p>
            </div>
          </div>
          <p
            className="font-sm color-orange"
            style={{
              textAlign: 'center',
              marginTop: '24px',
              lineHeight: '20px',
            }}
          >
            {t('paragraph.re-capture-warning')}
          </p>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        {isEmpty(image) ? (
          <>
            <PrimaryButton variant="outline" text={t('button.back')} onClick={goBack} />
            <PrimaryButton variant="solid" text={t('button.verify')} onClick={handleCaptureImage} />
          </>
        ) : (
          !isLoadingVerify && (
            <>
              <PrimaryButton
                variant="outline"
                text={t('button.re-capture')}
                disabled={isLoadingConfirm}
                onClick={handleReCapture}
              />
              {isScanSuccess && (
                <PrimaryButton
                  variant="solid"
                  text={t('button.confirm')}
                  loading={isLoadingConfirm}
                  onClick={onOpen}
                />
              )}
            </>
          )
        )}
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
        confirmTitle={t('button.confirm')}
        confirmContent={t('paragraph.sure-about-info')}
      />
      <BottomSpace />
    </div>
  ) : (
    <></>
  );
}
