import styles from './styles.module.scss';
import { Divider, Skeleton } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import { SecondaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { useEffect, useMemo, useState } from 'react';
import { ContractTransactionType, FreezeTransactionType, TransactionPTType } from 'types';
import { UserService } from 'services';
import { delayNavigate, formatDateTime, formatPrice, isSuccessCode, isVN, showData } from 'utils';
import PTService from 'services/pt.service';
import { TRANSACTION_TYPE } from 'enum';
import FreezeService from 'services/freeze.service';

type Props = {
  transactionID?: string;
};

export function OnlineSuccessContainer({ transactionID = '' }: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const ptService = new PTService();
  const freezeService = new FreezeService();
  const { goTo } = useDirection();
  const [transactionDetail, setTransactionDetail] = useState<
    ContractTransactionType & TransactionPTType & FreezeTransactionType
  >();
  const [isLoading, setIsLoading] = useState(false);

  const getTransactionDetail = async () => {
    setIsLoading(true);
    const response =
      transactionType === TRANSACTION_TYPE.PT
        ? await ptService.getPTTransactionDetail({
            transactionId: String(requestId),
          })
        : transactionType === TRANSACTION_TYPE.MEMBERSHIP
        ? await userService.getMemberTransactionDetail({
            transactionId: String(requestId),
          })
        : await freezeService.getFreezeTransaction({
            transactionId: String(requestId),
          });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setTransactionDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const requestId = useMemo(() => {
    const split = transactionID.split('_');
    const id = split[4];
    if (id) {
      return id;
    } else return '';
  }, [transactionID]);

  const transactionType = useMemo(() => {
    const split = transactionID.split('_');
    const code = split[1];
    if (code.includes('PT')) return TRANSACTION_TYPE.PT;
    if (code.includes('FR')) return TRANSACTION_TYPE.FREEZE;
    if (code.includes('MS')) return TRANSACTION_TYPE.MEMBERSHIP;
    else return TRANSACTION_TYPE.MEMBERSHIP;
  }, [transactionID]);

  const paymentMethod = useMemo(() => {
    switch (transactionType) {
      case TRANSACTION_TYPE.MEMBERSHIP:
        return isVN()
          ? transactionDetail?.contractTransactionPayment?.paymentMethod?.nameVi
          : transactionDetail?.contractTransactionPayment?.paymentMethod?.nameEn;
      case TRANSACTION_TYPE.FREEZE:
        return isVN()
          ? transactionDetail?.freezeTransactionPayment?.paymentMethod?.nameVi
          : transactionDetail?.freezeTransactionPayment?.paymentMethod?.nameEn;
      default:
        return null;
    }
  }, [transactionType, transactionDetail]);

  const transactionName = useMemo(() => {
    switch (transactionType) {
      case TRANSACTION_TYPE.MEMBERSHIP:
        return isVN()
          ? transactionDetail?.memberContract?.paymentPlan?.nameVi
          : transactionDetail?.memberContract?.paymentPlan?.nameEn;
      case TRANSACTION_TYPE.FREEZE:
        return t('title.freeze');
      default:
        return null;
    }
  }, [transactionType, transactionDetail]);

  useEffect(() => {
    if (requestId) {
      getTransactionDetail();
    }
  }, [requestId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className="color-black font-xl font-bold">{t('title.receipt')}</p>
        <Divider
          style={{
            borderColor: '#00EBCD',
            margin: '0',
            width: '100%',
          }}
        />
        {isLoading ? (
          <Skeleton
            animated
            style={{
              width: '80%',
              height: 'var(--font-xxl)',
            }}
          />
        ) : (
          <p className="color-black font-xxl font-bold">{showData(transactionName)}</p>
        )}

        <ReactSVG src="/images/icon/success.svg" />
        <div className={styles.content}>
          <div className={styles.item}>
            <p className="font-sm color-black font-bold">{t('title.total')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '120px',
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="font-md color-black font-bold">
                {showData(formatPrice(Number(transactionDetail?.paymentTotal)))}
              </p>
            )}
          </div>
          <Divider
            style={{
              borderColor: '#00EBCD',
              margin: '0',
              width: '100%',
            }}
          />
          {transactionType != TRANSACTION_TYPE.FREEZE && (
            <>
              <div className={styles.item}>
                <p className="font-sm color-black font-bold">{t('title.discount')}</p>
                {isLoading ? (
                  <Skeleton
                    animated
                    style={{
                      width: '120px',
                      height: 'var(--font-md)',
                    }}
                  />
                ) : (
                  <p className="font-md color-orange font-bold">
                    {Number(transactionDetail?.discount) > 0
                      ? `-${formatPrice(Number(transactionDetail?.discount))}`
                      : formatPrice(Number(transactionDetail?.discount))}
                  </p>
                )}
              </div>
              <Divider
                style={{
                  borderColor: '#00EBCD',
                  margin: '0',
                  width: '100%',
                }}
              />
            </>
          )}
          <div className={styles.item}>
            <p className="font-sm color-black font-bold">{t('title.transaction-amount')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '120px',
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="font-md color-blue font-bold">
                {showData(formatPrice(Number(transactionDetail?.transactionAmount)))}
              </p>
            )}
          </div>
          <Divider
            style={{
              borderColor: '#00EBCD',
              margin: '0',
              width: '100%',
            }}
          />
          <div className={styles.item}>
            <p className="font-sm color-black font-bold">{t('title.payment-method')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '120px',
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm color-black">{showData(paymentMethod)}</p>
            )}
          </div>
          <Divider
            style={{
              borderColor: '#00EBCD',
              margin: '0',
              width: '100%',
            }}
          />
          <div className={styles.item}>
            <p className="font-sm color-black font-bold">{t('title.payment-date')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '120px',
                  height: 'var(--font-sm)',
                }}
              />
            ) : (
              <p className="font-sm color-black">
                {showData(formatDateTime(transactionDetail?.purchaseDate))}
              </p>
            )}
          </div>
          <Divider
            style={{
              borderColor: '#00EBCD',
              margin: '0',
              width: '100%',
            }}
          />
          <div className={styles.item}>
            <p className="font-sm color-black font-bold">{t('title.transaction-code')}</p>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '150px',
                  height: 'var(--font-us)',
                }}
              />
            ) : (
              <p className="font-us color-black">{showData(transactionID)}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.bottomButton}>
        <SecondaryButton
          text={t('title.go-home')}
          variant="high-light-blue"
          style={{
            width: '100%',
          }}
          onClick={goTo(ROUTES.HOME)}
        />
      </div>
    </div>
  );
}
