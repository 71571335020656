import {
  getCityHaveClubAction,
  getFreezeDurationAction,
  getFreezePriceAction,
  getPaymentMethodAction,
} from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIResponse } from 'types';
import { CityType, FreezeConfigType, PaymentMethodType } from 'types/general.type';
import { isSuccessCode } from 'utils';

interface State {
  cityHaveClub?: CityType[];
  paymentMethods?: PaymentMethodType[];
  freezePriceConfig?: FreezeConfigType;
  freezeDurationConfig?: FreezeConfigType;
}

const initialState: State = {
  cityHaveClub: [],
  paymentMethods: [],
  freezePriceConfig: {},
  freezeDurationConfig: {},
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCityHaveClubAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.cityHaveClub = action.payload.data;
        }
      },
    );
    builder.addCase(
      getPaymentMethodAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.paymentMethods = action.payload.data;
        }
      },
    );
    builder.addCase(getFreezePriceAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.freezePriceConfig = action.payload.data;
      }
    });
    builder.addCase(
      getFreezeDurationAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        if (isSuccessCode(action.payload.code)) {
          state.freezeDurationConfig = action.payload.data;
        }
      },
    );
  },
});

export const {} = generalSlice.actions;
export default generalSlice.reducer;
