import React from 'react';
import { showData } from 'utils';

type Props = {
  text: string;
  isHighLight?: boolean;
  fontSize?: string;
};

function CustomTitle({ text = '', isHighLight = false, fontSize = '18px' }: Props) {
  return (
    <p
      className={isHighLight ? ' color-blue font-bold' : 'font-md color-black font-bold'}
      style={{
        fontSize: fontSize,
      }}
    >
      {showData(text)}
    </p>
  );
}

export default React.memo(CustomTitle);
