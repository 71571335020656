import React from 'react';
import styles from './styles.module.scss';
import { ReactSVG } from 'react-svg';
import { showData } from 'utils';
import { Skeleton } from 'antd-mobile';

type Props = {
  value?: string;
  options?: {
    title?: string;
    value?: string;
    url?: string;
  }[];
  onSelect?: (newValue: string) => void;
  isSkeleton?: boolean;
};

function PaymentMethodRadio({ value = '', options = [], isSkeleton = false, onSelect }: Props) {
  const handleChangeActiveKey = (key: string) => () => {
    onSelect && onSelect(key);
  };

  return (
    <div className={styles.wrapper}>
      {isSkeleton ? (
        <>
          <Skeleton className={styles.itemSkeleton} animated />
          <Skeleton className={styles.itemSkeleton} animated />
        </>
      ) : (
        options.map((option) => {
          return (
            <div
              key={option.value}
              className={`${styles.item} ${value === option.value ? styles.active : ''}`}
              onClick={handleChangeActiveKey(String(option.value))}
            >
              <ReactSVG src={String(option.url)} />
              <p className="font-ls color-blue font-bold">{showData(option.title)}</p>
            </div>
          );
        })
      )}
    </div>
  );
}

export default React.memo(PaymentMethodRadio);
