import styles from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';
import { ComparePlan, Faqs, PriorityMembershipPlan } from 'components';
import TabWrapper from 'components/shared/TabWrapper';

import { Element } from 'react-scroll';
import { useDirection, useDisclosure, useFormData } from 'hooks';

import GeneralService from 'services/general.service';
import {
  delayNavigate,
  generateClubName,
  isSuccessCode,
  isVN,
  LocalStorage,
  scrollToId,
  showData,
  STORAGE_KEY,
} from 'utils';
import { useEffect, useRef, useState } from 'react';
import { ClubType } from 'types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';
import { ROUTES } from 'constant';
import GlobalService from 'services/global.service';
import { PaymentPlanType } from 'types/global.type';
import {
  BASIC_BASIC_INFO,
  BASIC_MORE_INFO,
  BLACK_CARD_BASIC_INFO,
  BLACK_CARD_MORE_INFO,
} from 'constant';
import { useDispatch } from 'stores';
import { setLoadingToast } from 'stores/common';
import ApplyCodeInput from 'components/input/ApplyCodeInput';

const Storage = new LocalStorage();

const ACTIVE_TAB = {
  BLACK_CARD: 'black-card',
  BASIC: 'basic',
  COMPARE: 'compare',
};

type PromotionForm = {
  promotionCode: string;
};

const initPromotionForm: PromotionForm = {
  promotionCode: '',
};

export function SelectPlanContainer() {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const { clubId } = useParams();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const generalService = new GeneralService();
  const globalService = new GlobalService();
  const [clubDetail, setClubDetail] = useState<ClubType>();
  const [plans, setPlans] = useState<PaymentPlanType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>(ACTIVE_TAB.BLACK_CARD);
  const { formData, onChangeForm, KEY } = useFormData<PromotionForm>(initPromotionForm);
  const [isPromotion, setIsPromotion] = useState<boolean | null>(null);
  const [isPromotionComplete, setIsPromotionComplete] = useState<boolean>(false);
  const [promotionExtendError, setPromotionExtendError] = useState('');
  const promotionRef = useRef('');
  const planSaved = useRef<PaymentPlanType[]>([]);

  const getClubDetail = async () => {
    setIsLoading(true);
    const response = await generalService.getClubDetail({
      clubId,
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getPlansInClubs = async () => {
    setIsLoadingPlan(true);
    const response = await globalService.getPaymentPlans({
      clubId: String(clubId),
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setPlans(data);
      planSaved.current = data;
    }
    delayNavigate(() => setIsLoadingPlan(false));
  };

  const onCheckPromotion = async () => {
    if (!formData.promotionCode) return;
    dispatch(setLoadingToast(true));
    if (formData.promotionCode && formData.promotionCode != promotionRef.current) {
      const response = await globalService.getPaymentPlans({
        clubId: String(clubId),
        coupon: formData.promotionCode,
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setIsPromotion(true);
        setPromotionExtendError('');
        setPlans(data);
        Storage.setStorageItem(STORAGE_KEY.SAVED_COUPON, formData.promotionCode);
      } else {
        setIsPromotion(false);
        setPromotionExtendError(`error-message-code.${code}`);
      }
    } else {
      setIsPromotion(false);
      setPromotionExtendError(`validate-message.promo-code-invalid`);
    }
    setIsPromotionComplete(true);

    dispatch(setLoadingToast(false));
    promotionRef.current = formData.promotionCode;
  };

  const handleChangeActiveTab = (newTab: number | string) => {
    setActiveTab(newTab);
    scrollToId(String(newTab), 120);
  };

  const handleSelectPlan = (membershipId: number | string) => () => {
    goTo(
      ROUTES.MEMBERSHIP_PAYMENT.replace(':clubId', String(clubId)).replace(
        ':paymentPlanId',
        String(membershipId),
      ),
    )();
  };

  const handleRemovePromotion = async () => {
    setIsPromotionComplete(false);
    setIsPromotion(null);
    onChangeForm(KEY.PROMOTION_CODE)('');
    setPromotionExtendError('');
    promotionRef.current = '';
    setPlans(planSaved.current);
  };

  useEffect(() => {
    getClubDetail();
    getPlansInClubs();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.clubWrapper}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-lg)',
            }}
          />
        ) : (
          <>
            <p className="font-lg color-dark-black font-bold">
              {generateClubName(isVN() ? clubDetail?.nameVi : clubDetail?.nameEn)}
            </p>
            <Link
              className="font-md color-blue font-light"
              to={ROUTES.MEMBERSHIP}
              style={{
                textDecoration: 'none',
              }}
            >
              {t('button.change')}
            </Link>
          </>
        )}
      </div>
      <div className={styles.info}>
        <p className="font-xxl color-dark-black font-bold">{t('title.select-a-membership')}</p>
        <p
          className="font-sm color-dark-black font-light"
          style={{
            marginTop: '8px',
            marginBottom: '11px',
          }}
        >
          {t('title.select-right-membership')}
        </p>
        {!isOpen ? (
          <div className={styles.promoCode} onClick={onToggle}>
            <img alt="" src="/images/icon/tag.svg" />
            <p className="font-lg color-blue font-bold">{t('paragraph.you-have-promo')}</p>
          </div>
        ) : (
          <div className={styles.promotionInput}>
            <ApplyCodeInput
              label={t('title.promo-code')}
              value={formData.promotionCode}
              isComplete={isPromotionComplete}
              successMessage={isPromotion ? 'validate-message.success-promotion-code-filter' : ''}
              apiErrorMessage={promotionExtendError}
              onChangeValue={onChangeForm(KEY.PROMOTION_CODE)}
              onApply={onCheckPromotion}
              onRemove={handleRemovePromotion}
            />
          </div>
        )}
      </div>
      <div className={styles.tabs}>
        <TabWrapper
          activeKey={activeTab}
          items={[
            {
              key: ACTIVE_TAB.BLACK_CARD,
              title: 'Black Card',
            },
            {
              key: ACTIVE_TAB.BASIC,
              title: 'Basic',
            },
            {
              key: ACTIVE_TAB.COMPARE,
              title: t('title.compare'),
            },
          ]}
          onChangeTab={handleChangeActiveTab}
        />
      </div>
      <div className={styles.plan}>
        {isLoadingPlan ? (
          <>
            <div className={styles.item}>
              <PriorityMembershipPlan
                isBlackCard={true}
                isSkeleton
                basicInfos={[]}
                moreInfos={[]}
              />
            </div>
            <div className={styles.item}>
              <PriorityMembershipPlan
                isBlackCard={false}
                isSkeleton
                basicInfos={[]}
                moreInfos={[]}
              />
            </div>
          </>
        ) : (
          plans.map((plan) => {
            return (
              <div
                id={plan.isBlackCard ? ACTIVE_TAB.BLACK_CARD : ACTIVE_TAB.BASIC}
                key={plan.id}
                className={styles.item}
              >
                <PriorityMembershipPlan
                  isBlackCard={plan.isBlackCard}
                  mainInfo={{
                    description: plan.isBlackCard ? 'paragraph.pr13' : 'paragraph.pr2',
                    duration: Number(plan.duration?.value),
                    planName: showData(isVN() ? plan.nameVi : plan.nameEn),
                    price: plan.membershipFee || 0,
                    slogan: plan.isBlackCard ? 'paragraph.pr14' : 'paragraph.pr18',
                    unit: `title.${plan.duration?.unit}`,
                    tagName: plan.isBlackCard ? 'title.best-value' : '',
                  }}
                  basicInfos={plan.isBlackCard ? BLACK_CARD_BASIC_INFO : BASIC_BASIC_INFO}
                  moreInfos={plan.isBlackCard ? BLACK_CARD_MORE_INFO : BASIC_MORE_INFO}
                  onSelect={handleSelectPlan(String(plan.id))}
                />
              </div>
            );
          })
        )}
      </div>
      <div id={ACTIVE_TAB.COMPARE}>
        <Element name="firstInsideContainer">
          <div className={styles.comparePlan}>
            <ComparePlan />
          </div>
        </Element>
      </div>

      <div className={styles.faqs}>
        <Faqs />
      </div>
    </div>
  );
}
