import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useMemo } from 'react';
import { OnlineFailContainer, OnlineSuccessContainer } from 'containers';
import { useTranslation } from 'react-i18next';
import { VN_PAY_RESPONSE } from 'types';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { THEME_COLOR } from 'constant';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function VNPayStatusPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);
  const { search } = useLocation();
  const { t } = useTranslation();

  const transactionStatus = useMemo(() => {
    const data = queryString.parse(search) as VN_PAY_RESPONSE;

    return data;
  }, [search]);

  return (
    <SecondaryLayout title={'title.payment-status'} isHideFooter isWhiteBg isHideBack isHideHome>
      {transactionStatus.vnp_TransactionStatus === '00' ? (
        <OnlineSuccessContainer transactionID={transactionStatus.vnp_TxnRef} />
      ) : (
        <OnlineFailContainer
          message={
            transactionStatus && transactionStatus.vnp_TransactionStatus
              ? t(`vn-pay.${transactionStatus.vnp_TransactionStatus}`)
              : ''
          }
        />
      )}
    </SecondaryLayout>
  );
}
