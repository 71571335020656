import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { GeneralService } from 'services';
import { ClubType } from 'types';
import {
  delayNavigate,
  getDistanceFromLatLonInKm,
  getLocation,
  isSuccessCode,
  isVN,
  joinAddress,
} from 'utils';
import { useDirection } from 'hooks';
import _, { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import CitySelector from 'components/input/CitySelector';
import { CityType } from 'types/general.type';
import { BottomSpace, ClubCard } from 'components';
import { ROUTES } from 'constant';

type Props = {};

export default function PlansTab({}: Props) {
  const generalService = new GeneralService();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listCity, setListCity] = useState<CityType[]>([]);
  const [selectedCityId, setSelectedCityId] = useState<null | number>(null);
  const [clubMapping, setClubMapping] = useState<ClubType[]>([]);
  const [isLoadingDistance, setIsLoadingDistance] = useState(false);
  const [isLoadingClub, setIsLoadingClub] = useState(false);
  const [distanceMapping, setDistanceMapping] = useState<{
    [key: string]: { id: string; distance: number };
  }>({});
  const [location, setLocation] = useState({
    lat: 0,
    long: 0,
  });

  const getListCityHaveClub = async () => {
    const response = await generalService.getCityHaveClub();
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setListCity(data);
    }
  };

  const getListClub = async () => {
    setIsLoadingClub(true);
    const response = await generalService.getClubInCity({ cityId: undefined });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubMapping(data);
    }
    delayNavigate(() => setIsLoadingClub(false));
  };

  const handleChangeCity = (cityId: number | null) => {
    setSelectedCityId(cityId);
  };

  const filterClubByCityId = (cityId: number | null) => {
    if (!isNull(cityId)) {
      return sortClub.filter((club) => club.cityId === cityId);
    } else {
      return sortClub;
    }
  };

  const success = (position: GeolocationPosition) => {
    setLocation({
      lat: position.coords.latitude,
      long: position.coords.longitude,
    });

    setIsLoadingDistance(false);
  };

  const sortClub = useMemo(() => {
    if (_.isEmpty(distanceMapping)) return clubMapping;
    else {
      const sortedClubs: ClubType[] = [];
      const clubKeyBy = _.keyBy(clubMapping, 'id');
      const mappings = _.sortBy(_.valuesIn(distanceMapping), 'distance');
      mappings.forEach((club) => {
        sortedClubs.push(clubKeyBy[club.id]);
      });

      return sortedClubs;
    }
  }, [distanceMapping, clubMapping]);

  useEffect(() => {
    const result: { id: string | number | undefined; distance: number }[] = [];
    clubMapping.forEach((clubs) => {
      if (clubs.latitude && clubs.longitude && location.lat && location.long) {
        const data = getDistanceFromLatLonInKm(
          location.lat,
          location.long,
          clubs.latitude,
          clubs.longitude,
        );
        result.push({ id: clubs.id, distance: Math.ceil(data * 10) / 10 });
      }
    });
    setDistanceMapping(_.keyBy(result, 'id') as any);
  }, [clubMapping, location]);

  useEffect(() => {
    getLocation(
      () => {
        setIsLoadingDistance(true);
      },
      success,
      (positionError: GeolocationPositionError) => {
        setIsLoadingDistance(false);
        console.log('Error: ', positionError);
      },
      () => {
        setIsLoadingDistance(false);
      },
    );
  }, []);

  useEffect(() => {
    getListCityHaveClub();
    getListClub();
  }, []);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.chipWrapper}>
        <CitySelector
          options={listCity}
          selectedId={selectedCityId}
          onChangeCity={handleChangeCity}
        />
      </div>
      <p className={styles.clubNearYouTitle}>{t('title.find-club-near-you')}</p>
      <div className={styles.clubCardList}>
        {isLoadingDistance || isLoadingClub
          ? [1, 2, 3, 4].map((_item, idx) => {
              return (
                <div key={idx} className={styles.clubCard}>
                  <ClubCard isSkeleton />
                </div>
              );
            })
          : filterClubByCityId(selectedCityId).map((club) => (
              <div key={club.id} className={styles.clubCard}>
                <ClubCard
                  clubName={isVN() ? club.nameVi : club.nameEn}
                  clubAddress={joinAddress(club)}
                  clubDistance={distanceMapping[String(club.id)]?.distance}
                  onReviewPlan={goTo(ROUTES.MEMBERSHIP_SELECT.replace(':clubId', String(club.id)))}
                  onSeeDetail={goTo(
                    ROUTES.MEMBERSHIP_CLUB_DETAIL.replace(':clubId', String(club.id)),
                  )}
                />
              </div>
            ))}
        <BottomSpace />
      </div>
    </div>
  );
}
