import React from 'react';
import styles from './styles.module.scss';
import { Form, Input, InputProps } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
  errorMessage?: string;
};

function PrimaryInput({ title, errorMessage = '', ...rest }: Props & InputProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Form layout="vertical">
        <Form.Item className={styles.title}>
          {title && (
            <p
              className="font-medium color-white font-lg"
              style={{
                textTransform: 'uppercase',
              }}
            >
              {title}
            </p>
          )}
        </Form.Item>

        <Form.Item className={styles.input}>
          <Input {...rest} />
        </Form.Item>
        {errorMessage && (
          <Form.Item className={styles.errorMessage}>
            <p className="error-message">{t(`validate-message.${errorMessage}`)}</p>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default React.memo(PrimaryInput);
