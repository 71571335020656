/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { Avatar, FloatingPanelRef, Skeleton } from 'antd-mobile';
import { IoChevronForward } from 'react-icons/io5';
import { CustomSwitch } from 'components';
import { useDirection, useDisclosure } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserProfileAction, userSelector } from 'stores/user';
import _ from 'lodash';
import { LocalStorage, STORAGE_KEY, delayNavigate, getImageUrl, showData } from 'utils';
import { useDispatch } from 'stores';
import { LANGUAGE } from 'enum';
import { ROUTES } from 'constant';
import { FaCheck } from 'react-icons/fa';
import SvgIcon from 'components/shared/SVGIcon';
import ConfirmModal from 'components/modal/ConfirmModal';

const Storage = new LocalStorage();

type Props = {};

export function AccountContainer({}: Props) {
  const { t, i18n } = useTranslation();
  const { goTo } = useDirection();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();
  const { userProfile } = useSelector(userSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  const handleChangeLanguage = (checked: boolean) => {
    if (checked) {
      setLanguage(LANGUAGE.EN);
      Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.EN);
      i18n.changeLanguage(LANGUAGE.EN);
    } else {
      setLanguage(LANGUAGE.VN);
      Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.VN);
      i18n.changeLanguage(LANGUAGE.VN);
    }
  };

  const logout = () => {
    Storage.clearAllStorage();
    window.location.href = ROUTES.LADING;
  };

  const initCallAPI = async () => {
    if (_.isEmpty(userProfile)) {
      setIsLoading(true);
      await dispatch(getUserProfileAction());
    }
    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.information}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '110px',
                height: '110px',
                borderRadius: '110px',
              }}
            />
          ) : (
            <Avatar
              className={styles.avatar}
              src={userProfile?.avatar ? getImageUrl(String(userProfile?.avatar)) : ''}
            />
          )}
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '60%',
                height: 'var(--font-xl)',
              }}
            />
          ) : (
            <p className="font-xl font-bold color-white">{showData(userProfile?.fullName)}</p>
          )}
        </div>
        <div className={styles.controller}>
          <div className={styles.item} onClick={goTo(ROUTES.ACCOUNT_INFORMATION)}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/edit.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.my-information')}</p>
            </div>
            <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
          </div>
          <div
            className={styles.item}
            onClick={
              userProfile?.idCardUploaded
                ? () => {
                    return;
                  }
                : goTo(ROUTES.ACCOUNT_VERIFICATION)
            }
          >
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/id-card.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.id-card-verify')}</p>
            </div>
            {!isLoading &&
              (userProfile?.idCardUploaded ? (
                <FaCheck color="var(--sub-high-light-color)" fontSize="20px" />
              ) : (
                <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
              ))}
          </div>
          <div className={styles.item}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/language.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.language')}</p>
            </div>
            <CustomSwitch
              defaultText="vie"
              activeText="eng"
              isChecked={language === LANGUAGE.EN}
              onChange={handleChangeLanguage}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/noti-setting.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.notify-setting')}</p>
            </div>
            <CustomSwitch defaultText="off" activeText="on" />
          </div>
          {/* <div className={styles.item} onClick={goTo(ROUTES.ACCOUNT_ABOUT)}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <ReactSVG src="/images/icon/menu.svg" />
              </div>
              <p className="color-black font-sm">{t('title.tng-about')}</p>
            </div>
            <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
          </div> */}
          {/* <div className={styles.item} onClick={goTo(ROUTES.ACCOUNT_PRIVACY)}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <ReactSVG src="/images/icon/menu.svg" />
              </div>
              <p className="color-black font-sm">{t('title.privacy-policy')}</p>
            </div>
            <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
          </div> */}
          <div className={styles.item} onClick={goTo(ROUTES.ACCOUNT_TERM_CONDITION)}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/menu.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.term-condition')}</p>
            </div>
            <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
          </div>
          <div className={styles.item} onClick={goTo(ROUTES.ACCOUNT_TERM_CONDITION)}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/cellphone.svg" pathFill="var(--primary-text-color)" />
              </div>
              <p className="color-black font-sm">{t('title.call-us')}</p>
            </div>
            <IoChevronForward color="var(--primary-text-color)" fontSize="20px" />
          </div>
          <div className={styles.item} onClick={onOpen}>
            <div className={styles.content}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/logout.svg" pathFill="var(--high-light-color)" />
              </div>
              <p className="color-orange font-sm">{t('title.sign-out')}</p>
            </div>
          </div>
          <div
            className={styles.item}
            style={{
              justifyContent: 'flex-end',
            }}
          >
            <div className={styles.content}>
              <SvgIcon src="/images/icon/version.svg" pathFill="var(--primary-text-color)" />
              <p className="color-black font-sm">{t('title.version')} 10.5.2</p>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        confirmTitle={t('title.log-out')}
        confirmContent={t('paragraph.are-you-sure-logout')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={logout}
      />
    </div>
  );
}
