import { SafeArea } from 'antd-mobile';
import { MainPageTransition } from 'components/animation';
import React from 'react';
import { Outlet } from 'react-router';

function BlankLayout() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        flexDirection: 'column',
        background: ' var(#F2FAFC)',
      }}
    >
      <MainPageTransition>
        <Outlet />
        <div>
          <SafeArea position="bottom" />
        </div>
      </MainPageTransition>
    </div>
  );
}

export default React.memo(BlankLayout);
