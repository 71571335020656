import { createAsyncThunk } from '@reduxjs/toolkit';
import { GeneralService } from 'services';

export const getCityHaveClubAction = createAsyncThunk('general/getCityHaveClubAction', async () => {
  const generalService = new GeneralService();
  const response = await generalService.getCityHaveClub();

  return response;
});

export const getPaymentMethodAction = createAsyncThunk(
  'general/getPaymentMethodAction',
  async () => {
    const generalService = new GeneralService();
    const response = await generalService.getPaymentMethods();

    return response;
  },
);

export const getFreezePriceAction = createAsyncThunk('general/getFreezePriceAction', async () => {
  const generalService = new GeneralService();
  const response = await generalService.getFreezePrice();

  return response;
});

export const getFreezeDurationAction = createAsyncThunk(
  'general/getFreezeDurationAction',
  async () => {
    const generalService = new GeneralService();
    const response = await generalService.getFreezeDuration();

    return response;
  },
);
