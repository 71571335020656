import React from 'react';
import styles from './styles.module.scss';
import SvgIcon from '../SVGIcon';
import { SecondaryButton } from 'components/button';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY } from 'constant';

const INFOS = [
  {
    left: 'pr1',
    right: 'pr2',
  },
  {
    left: 'pr3',
    right: 'pr3',
  },
  {
    left: 'pr4',
    right: 'pr4',
  },
  {
    left: 'pr5',
    right: 'pr5',
  },

  // {
  //   left: 'pr6',
  //   right: null,
  // },
  {
    left: 'pr7',
    right: null,
  },

  // {
  //   left: 'pr8',
  //   right: null,
  // },
  {
    left: 'pr9',
    right: null,
  },
  {
    left: 'pr10',
    right: null,
  },
  {
    left: 'pr11',
    right: null,
  },
  {
    left: 'pr12',
    right: null,
  },
];

function ComparePlan() {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div
      className={styles.wrapper}
      style={{
        padding: isOpen ? '0' : '0 24px',
      }}
    >
      <div
        className={styles.closeBlock}
        style={{
          background: isOpen ? 'transparent' : 'var(--overlay-bg-color)',
          padding: isOpen ? '24px 0' : '24px',
        }}
      >
        <div
          className={styles.titleBlock}
          style={{
            padding: isOpen ? '0 24px' : '0',
          }}
        >
          <p
            className="color-black font-lg"
            style={{
              flexShrink: '100',
            }}
          >
            {t('paragraph.compare-title')}
          </p>
          <SvgIcon
            width="33px"
            height="33px"
            pathFill="var(--primary-text-color)"
            src={isOpen ? '/images/icon/minus-circle.svg' : '/images/icon/plus-circle.svg'}
            onClick={onToggle}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>

        <div
          className={styles.compareTable}
          style={{
            display: isOpen ? 'block' : 'none',
          }}
        >
          <div className={styles.blockInfo}>
            <div className={`${styles.card} ${styles.blackCard}`}>
              <SvgIcon pathFill="var(--white-text-color)" src="/images/icon/plan-card.svg" />
              <p>Black Card</p>
            </div>
            <div className={styles.card}>
              <SvgIcon pathFill="var(--primary-text-color)" src="/images/icon/plan-card.svg" />
              <p>Basic</p>
            </div>
          </div>
          {INFOS.map((info, idx) => {
            return (
              <div key={idx} className={styles.block}>
                <div className={styles.left}>
                  <p className="color-black font-md font-light">
                    {info.left ? t(`paragraph.${info.left}`) : DEFAULT_EMPTY}
                  </p>
                </div>
                <div className={styles.right}>
                  <p className="color-black font-md font-light">
                    {' '}
                    {info.right ? t(`paragraph.${info.right}`) : DEFAULT_EMPTY}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={styles.controller}
          style={{
            padding: isOpen ? '0 24px' : '0',
          }}
        >
          <SecondaryButton
            text={isOpen ? t('button.hide-compare-plan') : t('button.compare-plan')}
            variant={isOpen ? 'outline' : 'high-light-blue'}
            style={{
              width: '100%',
            }}
            onClick={onToggle}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(ComparePlan);
