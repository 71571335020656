import { Swiper, SwiperRef } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useRef } from 'react';

type Props = {
  activeIdx?: number;
  items?: React.ReactNode[];
  emptyElement?: React.ReactNode;
  onChangeIdx?: (idx: number) => void;
};

function CardSwiper({ activeIdx = 0, items = [], emptyElement, onChangeIdx }: Props) {
  const swiperRef = useRef<SwiperRef>(null);

  return items.length > 0 ? (
    <Swiper
      ref={swiperRef}
      defaultIndex={activeIdx}
      className={styles.listItem}
      style={{
        '--track-padding': '0 0 0 0',
      }}
      indicator={false}
      onIndexChange={onChangeIdx}
    >
      {items?.map((item, idx) => {
        return (
          <Swiper.Item key={idx}>
            <div className={styles.inner}>{item}</div>
          </Swiper.Item>
        );
      })}
    </Swiper>
  ) : (
    <div
      style={{
        padding: '0 0 16px',
      }}
    >
      {emptyElement}
    </div>
  );
}

export default React.memo(CardSwiper);
