import SVG, { Props as SVGProps } from 'react-inlinesvg';

interface Props {
  pathFill?: string;
}

function SvgIcon({ pathFill, ...props }: SVGProps & Props) {
  return (
    <SVG
      key={pathFill}
      {...props}
      preProcessor={(code) => {
        if (/stroke=".*?"/.test(code) && pathFill)
          return code.replace(/stroke=".*?"/g, `stroke="${pathFill ? pathFill : 'currentColor'}"`);
        else if (pathFill)
          return code.replace(/fill=".*?"/g, `fill="${pathFill ? pathFill : 'currentColor'}"`);
        else return code;
      }}
    />
  );
}

export default SvgIcon;
