import React from 'react';
import Header from './Header';
import styles from './styles.module.scss';
import { BottomSpace, PullToReload } from 'components';
import Footer from 'layouts/MainLayout/Footer';
import { useTranslation } from 'react-i18next';

type Prop = {
  title?: string;
  isHideHeader?: boolean;
  isHideFooter?: boolean;
  isHideBack?: boolean;
  isHideHome?: boolean;
  isHideSlogan?: boolean;
  isHideBottomSpace?: boolean;
  isWhiteBg?: boolean;
  headerBackground?: string;
  titleColor?: string;
  arrowColor?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
};

function SecondaryLayout({
  title = '',
  isHideFooter = false,
  isHideHeader = false,
  isHideSlogan = false,
  isHideBack = false,
  isHideBottomSpace = false,
  isWhiteBg = false,
  isHideHome = false,
  headerBackground = '',
  backgroundColor = 'var(--blue-bg-color)',
  titleColor = '',
  arrowColor = '',
  children,
}: Prop) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperLayout}>
      {!isHideSlogan && <p className={styles.slogan}>{t('paragraph.slogan')}</p>}
      {!isHideHeader && (
        <Header
          title={title}
          hideBack={isHideBack}
          isWhiteBg={isWhiteBg}
          hideHome={isHideHome}
          headerBackground={headerBackground}
          titleColor={titleColor}
          arrowColor={arrowColor}
        />
      )}
      <div
        id="main-body"
        className={styles.body}
        style={{
          background: backgroundColor,
        }}
      >
        <PullToReload>
          {children}
          {!isHideBottomSpace && <BottomSpace />}
        </PullToReload>
      </div>
      {!isHideFooter && <Footer />}
    </div>
  );
}

export default React.memo(SecondaryLayout);
