import { THEME_COLOR } from 'constant';
import { MembershipContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function MembershipPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout title="title.membership" isHideBottomSpace isHideHeader isWhiteBg>
      <MembershipContainer />
    </SecondaryLayout>
  );
}
