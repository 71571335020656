import React from 'react';
import styles from './styles.module.scss';
import { FaRegCircle, FaRegCircleDot } from 'react-icons/fa6';
import { Divider, SpaceProps } from 'antd-mobile';
import { showData } from 'utils';

type Props = {
  isActive?: boolean;
  name?: string;
  description?: string;
};

function PromotionCard({ isActive = false, name, description, ...rest }: Props & SpaceProps) {
  return (
    <div
      className={styles.cardWrapper}
      style={{
        background: isActive ? 'var(--primary-color)' : 'var(--primary-bg-color)',
      }}
      {...rest}
    >
      <div className={styles.content}>
        <p className={`font-md font-bold ${isActive ? 'color-white' : 'color-black'}`}>
          {showData(name)}
        </p>
        <p className={`font-xs font-medium ${isActive ? 'color-white' : 'color-black'}`}>
          {showData(description)}
        </p>
      </div>
      <Divider
        direction="vertical"
        style={{
          borderLeft: '1px dashed white',
          height: '40px',
          margin: 0,
          position: 'absolute',
          right: '45px',
          top: '20px',
          borderColor: isActive ? 'var(--white-text-color)' : 'var(--primary-text-color)',
        }}
      />
      <div>
        {isActive ? (
          <FaRegCircleDot color="var(--white-text-color)" fontSize="20px" />
        ) : (
          <FaRegCircle color="var(--sub-text-color)" fontSize="20px" />
        )}
      </div>
    </div>
  );
}

export default React.memo(PromotionCard);
