import { TabWrapper } from 'components';
import styles from './styles.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorage, STORAGE_KEY, resetScroll } from 'utils';
import { MainPageTransition } from 'components/animation';
import PlansTab from './PlansTab';
import { FreezeTab } from './FreezeTab';

type Props = {};

const TABS = {
  UPGRADE: 'UPGRADE',
  PLANS: 'PLANS',
  FREEZE: 'FREEZE',
};

const Storage = new LocalStorage();

export function MembershipContainer({}: Props) {
  const previousTab = Storage.getStorageItem(STORAGE_KEY.MEMBERSHIP_TAB);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(
    previousTab ? previousTab : TABS.PLANS,
  );
  const TAB_ITEMS = [
    {
      key: TABS.UPGRADE,
      title: t('title.upgrade'),
    },
    {
      key: TABS.PLANS,
      title: t('title.plans'),
    },
    {
      key: TABS.FREEZE,
      title: t('title.freeze'),
    },
  ];

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(key);
    Storage.setStorageItem(STORAGE_KEY.MEMBERSHIP_TAB, key);
    resetScroll();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TabWrapper activeKey={selectedTab} items={TAB_ITEMS} onChangeTab={handleChangeTab} />
      </div>
      <MainPageTransition>
        <div className={styles.body}>
          {selectedTab === TABS.PLANS && <PlansTab />}
          {selectedTab === TABS.FREEZE && <FreezeTab />}
          {/* {selectedTab === TABS.HISTORY && <HistoryTab />} */}
        </div>
      </MainPageTransition>
    </div>
  );
}
