/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { SecondaryButton, StatusChip } from 'components';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime, showData } from 'utils';
import { COMMON_STATUS } from 'types';
import { CHIP_STATUS } from 'enum';
import { Skeleton } from 'antd-mobile/es/components/skeleton/skeleton';
import { ReactSVG } from 'react-svg';
import { Rate } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import ConfirmModal from 'components/modal/ConfirmModal';

type Props = {
  status?: COMMON_STATUS;
  date?: string;
  startTime?: string;
  endTime?: string;
  isSkeleton?: boolean;
  rating?: number;
  onRate?: (rate: number) => void;
  onDelete?: () => void;
};

function HistoryBookingCard({
  date,
  rating,
  endTime,
  startTime,
  status,
  onDelete,
  onRate,
  isSkeleton = false,
}: Props) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const [rate, setRate] = useState(5);

  const icon = useMemo(() => {
    switch (status) {
      case CHIP_STATUS.WAITPTCONFIRM:
        return '/images/icon/circle-menu.svg';
      case CHIP_STATUS.CANCELLED:
        return '/images/icon/cancel.svg';
      case CHIP_STATUS.COMPLETED:
        return '/images/icon/success.svg';
      case CHIP_STATUS.BOOKED:
        return '/images/icon/calendar.svg';
      default:
        return '';
    }
  }, [status]);

  const handleChangeRate = (newRate: number) => {
    if (newRate >= 1) {
      setRate(newRate);
    }
  };

  const handleRating = () => {
    onRate && onRate(rate);
  };

  return (
    <div className={styles.border}>
      <div className={styles.borderWrapper}>
        <div className={styles.cardWrapper}>
          <div className={styles.icon}>
            <ReactSVG src={icon} />
          </div>
          <div className={styles.information}>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="color-back font-bold font-md">{formatDate(date)}</p>
            )}
            <div className={styles.rangeTime}>
              <div className={styles.rangeItem}>
                <p className="color-back font-sm">{t('title.start-time')}</p>
                {isSkeleton ? (
                  <Skeleton
                    animated
                    style={{
                      height: 'var(--font-md)',
                    }}
                  />
                ) : (
                  <p className="color-back font-bold font-md">{formatTime(startTime)}</p>
                )}
              </div>
              <div className={styles.rangeItem}>
                <p className="color-back font-sm">{t('title.end-time')}</p>
                {isSkeleton ? (
                  <Skeleton
                    animated
                    style={{
                      height: 'var(--font-md)',
                    }}
                  />
                ) : (
                  <p className="color-back font-bold font-md">{formatTime(endTime)}</p>
                )}
              </div>
            </div>

            {((status === CHIP_STATUS.COMPLETED && rating) || (isOpen && !rating)) && (
              <div className={styles.rating}>
                <Rate
                  value={rating ? rating : rate}
                  onChange={
                    rating
                      ? () => {
                          return;
                        }
                      : handleChangeRate
                  }
                />
              </div>
            )}
          </div>
          <div className={styles.button}>
            {status === CHIP_STATUS.WAITPTCONFIRM && (
              <SecondaryButton
                text={t('button.cancel')}
                variant="outline-orange"
                onClick={onDelete}
                style={{
                  width: '100px',
                }}
              />
            )}
            {!rating &&
              status === CHIP_STATUS.COMPLETED &&
              (isOpen ? (
                <SecondaryButton
                  text={t('button.confirm')}
                  variant="high-light"
                  style={{
                    width: '100px',
                  }}
                  onClick={onOpenConfirm}
                />
              ) : (
                <SecondaryButton
                  text={t('button.rating')}
                  variant="outline"
                  style={{
                    width: '100px',
                  }}
                  onClick={onOpen}
                />
              ))}
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={handleRating}
        confirmTitle={t('paragraph.rating-booking-confirm')}
      />
    </div>
  );
}

export default React.memo(HistoryBookingCard);
