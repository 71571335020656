import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils';
import { COMMON_STATUS } from 'types';
import { CHIP_STATUS } from 'enum';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';

type Props = {
  status?: COMMON_STATUS;
  isSkeleton?: boolean;
};

function StatusChip({ status, isSkeleton = false }: Props) {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (status) {
      case CHIP_STATUS.SUCCESS:
        return 'var(--sub-high-light-text-color)';
      case CHIP_STATUS.FAIL:
        return 'var(--error-text-color)';
      case CHIP_STATUS.WAITFORPAYMENT:
        return 'var(--primary-color)';
      case CHIP_STATUS.WAITPTCONFIRM:
        return 'var(--sub-text-color)';
      case CHIP_STATUS.BOOKED:
        return 'var(--primary-color)';
      case CHIP_STATUS.CANCELLED:
        return 'var(--error-text-color)';
      case CHIP_STATUS.COMPLETED:
        return 'var(--sub-high-light-text-color)';
      case CHIP_STATUS.REJECTED:
        return 'var(--error-text-color)';
      default:
        return 'var(--sub-text-color)';
    }
  }, [status]);

  return isSkeleton ? (
    <Skeleton
      animated
      style={{
        height: '23px',
        width: '100px',
        borderRadius: '3px',
      }}
    />
  ) : (
    <div
      className={styles.wrapper}
      style={{
        borderColor: color,
      }}
    >
      <p
        className="font-sm font-medium"
        style={{
          color: color,
        }}
      >
        {showData(status ? t(`chip.${status}`) : '')}
      </p>
    </div>
  );
}

export default React.memo(StatusChip);
