import { useState } from 'react';
import styles from './styles.module.scss';
import { TabWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { resetScroll } from 'utils';
import ContractTab from './ContractTab';

type Props = {};

const TABS = {
  CONTRACT: 'CONTRACT',
  TRANSACTION: 'TRANSACTION',
};

export function PTContractContainer({}: Props) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(TABS.CONTRACT);
  const TAB_ITEMS = [
    {
      key: TABS.CONTRACT,
      title: t('title.contract'),
    },
    {
      key: TABS.TRANSACTION,
      title: t('title.history'),
    },
  ];

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(key);
    resetScroll();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <TabWrapper activeKey={selectedTab} items={TAB_ITEMS} onChangeTab={handleChangeTab} />
      </div>
      <div className={styles.content}>{selectedTab === TABS.CONTRACT && <ContractTab />}</div>
    </div>
  );
}
