import { PullToRefresh } from 'antd-mobile';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
};

function PullToReload({ children }: Props) {
  const { t } = useTranslation();

  return (
    <PullToRefresh
      completeDelay={0}
      completeText={t('title.reload')}
      refreshingText={t('title.reload')}
      pullingText={t('title.pull-to-reload')}
      canReleaseText={t('title.pull-to-reload')}
      threshold={70}
      onRefresh={async () => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }}
    >
      {children}
    </PullToRefresh>
  );
}

export default React.memo(PullToReload);
