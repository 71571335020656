import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';
import { Skeleton } from 'antd-mobile/es/components/skeleton/skeleton';
import { PT_CONTRACT_TYPE } from 'types';
import { E_PT_CONTRACT_STATUS } from 'enum';

type Props = {
  name?: string;
  isSkeleton?: boolean;
  isLoadingRegister?: boolean;
  isDisabledRegister?: boolean;
  onRegister?: () => void;
  onPayment?: () => void;
  status?: PT_CONTRACT_TYPE;
};

function PTPlanCard({
  name,
  isSkeleton = false,
  isLoadingRegister = false,
  isDisabledRegister = false,
  status = E_PT_CONTRACT_STATUS.REGISTER,
  onRegister,
  onPayment,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.border}>
      <div className={styles.borderWrapper}>
        <div className={styles.cardWrapper}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '200px',
              }}
            />
          ) : (
            <p className="color-black font-lg">{showData(name)}</p>
          )}
          {!isSkeleton && (
            <div className={styles.button}>
              {status === E_PT_CONTRACT_STATUS.REGISTER && (
                <SecondaryButton
                  text={t('title.register')}
                  variant="high-light-blue"
                  loading={isLoadingRegister}
                  disabled={isDisabledRegister}
                  onClick={onRegister}
                />
              )}
              {status === E_PT_CONTRACT_STATUS.WAITPTCONFIRM && (
                <SecondaryButton disabled text={t('title.waiting-for-confirm')} variant="outline" />
              )}
              {status === E_PT_CONTRACT_STATUS.WAITFORPAYMENT && (
                <SecondaryButton
                  variant="high-light"
                  text={t('title.payment')}
                  onClick={onPayment}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(PTPlanCard);
