import { BottomSpace, EmptyCard, MainSelector, PTCard } from 'components';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { delayNavigate, isSuccessCode, isVN, resetScrollElement } from 'utils';
import _ from 'lodash';
import { changeSelectedContract, getUserContractsAction, userSelector } from 'stores/user';
import { useDirection } from 'hooks';
import PTService from 'services/pt.service';
import { useSelector } from 'react-redux';
import { PTType } from 'types';
import { useDispatch } from 'stores';
import { ROUTES } from 'constant';

type Props = {};

export function ChoosePTContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const ptService = new PTService();
  const { userContracts, selectedContract } = useSelector(userSelector);
  const [listPT, setListPT] = useState<PTType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPT, setIsLoadingPT] = useState(false);
  const dispatch = useDispatch();
  const initNumberOfCard = Math.ceil((window.innerHeight - 64 - 32 - 80 - 80) / 80);
  const [isEmpty, setIsEmpty] = useState(false);

  const initCallAPI = async () => {
    setIsLoading(true);
    await dispatch(getUserContractsAction());
    delayNavigate(() => setIsLoading(false));
  };

  const getListPT = async (clubId: string) => {
    setIsLoadingPT(true);
    const response = await ptService.getListPTInClub({
      clubId: clubId,
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListPT(data);
    } else {
      setListPT([]);
    }
    delayNavigate(() => setIsLoadingPT(false));
  };

  const handleChangeContract = (clubId: string | number) => {
    resetScrollElement('pt-list');
    const contract = userContracts?.find(
      (contract) => String(contract?.club?.id) === String(clubId),
    );
    contract && dispatch(changeSelectedContract(contract));
  };

  useEffect(() => {
    if (!_.isEmpty(selectedContract)) {
      getListPT(String(selectedContract.club?.id));
    }
  }, [selectedContract]);

  useEffect(() => {
    if (userContracts?.length === 0) setIsEmpty(true);
    else {
      setIsEmpty(false);
    }
  }, [userContracts]);

  useEffect(() => {
    initCallAPI();
  }, []);

  return (
    <div className={styles.container}>
      {!isLoading && isEmpty ? (
        <EmptyCard text={t('paragraph.no-membership-pt')} />
      ) : (
        <>
          <div className={styles.headerContainer}>
            <MainSelector
              isSkeleton={isLoading}
              iconUrl="/images/icon/building.svg"
              panelTitle={t('title.select-club')}
              placeHolder={t('title.select-club')}
              value={String(selectedContract?.club?.id)}
              options={userContracts?.map((contract) => {
                return {
                  title: String(isVN() ? contract.club?.nameVi : contract.club?.nameEn),
                  value: String(contract.club?.id),
                };
              })}
              onSelect={handleChangeContract}
            />
          </div>

          <div id="pt-list" className={styles.ptList}>
            {isLoadingPT
              ? Array.from(Array(initNumberOfCard).keys()).map((temp) => {
                  return <PTCard key={temp} isSkeleton />;
                })
              : listPT.map((pt) => {
                  return (
                    <PTCard
                      key={pt.id}
                      ptName={[pt.accountDetail?.firstName, pt.accountDetail?.lastName].join(' ')}
                      groupName={pt.ptGroup?.groupName}
                      onRegister={goTo(ROUTES.PT_PLAN.replace(':ptId', String(pt.id)))}
                      imageUrl={pt.accountDetail?.avatar}
                      isHaveContract={!_.isEmpty(pt.ptContracts)}
                    />
                  );
                })}
            <BottomSpace />
          </div>
        </>
      )}
    </div>
  );
}
