import { FloatingPanel, PickerView } from 'antd-mobile';
import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'components/button';
import { showData } from 'utils';
import { PickerValue } from 'antd-mobile/es/components/picker-view';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
  value?: {
    hour: string;
    minute: string;
  };
  note?: string;
  onChange?: (payload: { hour: string; minute: string }) => void;
  onConfirm?: () => void;
};

type FloatingPanelRef = {
  setHeight: (
    height: number,
    options?: {
      immediate?: boolean; // whether to skip animation
    },
  ) => void;
};

function TimePicker({
  value,
  onChange,
  isOpen,
  isLoading = false,
  note = '',
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation();
  const refPanel = useRef<FloatingPanelRef>(null);
  const newRef = useRef<HTMLDivElement>(null);

  const handleChange = (data: PickerValue[]) => {
    onChange &&
      onChange({
        hour: String(data[0]),
        minute: String(data[1]),
      });
  };

  const handleClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target) && !isLoading) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    if (refPanel.current) {
      if (isOpen) {
        refPanel.current.setHeight(350);
      } else {
        refPanel.current.setHeight(0);
      }
    }
  }, [isOpen]);

  return createPortal(
    <div ref={newRef}>
      <FloatingPanel
        ref={refPanel}
        anchors={[0, 350]}
        className={styles.panel}
        handleDraggingOfContent={false}
      >
        {note && (
          <p
            className="font-xs color-grey"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(note)}
          </p>
        )}
        <div className={styles.labelGroup}>
          <p className="font-xl color-black">{t('title.hour')}</p>
          <p className="font-xl color-black">{t('title.minute')}</p>
        </div>
        <PickerView
          value={[value?.hour, value?.minute] as PickerValue[]}
          columns={[
            Array.from(Array(24).keys()).map((ele) => ({
              label: String(ele).padStart(2, '0'),
              value: String(ele).padStart(2, '0'),
            })),
            Array.from(Array(60).keys()).map((ele) => ({
              label: String(ele).padStart(2, '0'),
              value: String(ele).padStart(2, '0'),
            })),
          ]}
          onChange={handleChange}
        />
        <SecondaryButton
          text={t('button.confirm')}
          variant="high-light"
          style={{
            position: 'absolute',
            bottom: '24px',
            width: 'calc(100% - 24px)',
          }}
          loading={isLoading}
          onClick={onConfirm}
        />
      </FloatingPanel>
    </div>,
    document.body,
  );
}

export default React.memo(TimePicker);
