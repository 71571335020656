import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { PrimaryButton } from 'components';
import { useDirection, useFormData, useValidation } from 'hooks';
import { ROUTES } from 'constant';
import { AuthService } from 'services';
import { JOI, delayNavigate, isSuccessCode } from 'utils';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import { Image } from 'antd-mobile';
import InputPhoneNumber from 'components/input/InputPhoneNumber';

type FormData = {
  phoneNumber: string;
};

const initForm: FormData = {
  phoneNumber: '',
};

export function LoginContainer() {
  const authService = new AuthService();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isLoading } = useSelector(commonSelector);
  const { formData, KEY, onChangeForm } = useFormData<FormData>(initForm);
  const { errorMessage, onValidate } = useValidation<FormData>({
    phoneNumber: JOI.PHONE_REQUIRE,
  });

  const sendOTPCode = async () => {
    const isError = onValidate(formData);
    if (!isError) {
      const response = await authService.sendOTPLogin(formData);
      const { code } = response;
      if (isSuccessCode(code)) {
        delayNavigate(
          goTo(ROUTES.VERIFY_OTP, {
            replace: true,
            state: {
              phoneNumber: formData.phoneNumber,
            },
          }),
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <Image className={styles.logo} src="/images/logo/logo-new-gym-3.png" width="80px" />
      <div className={styles.form}>
        <p className="font-xxl color-black font-bold">{t('title.what-is-your-phone')}</p>
        <div className={styles.input}>
          <InputPhoneNumber
            value={formData.phoneNumber}
            onChange={onChangeForm(KEY.PHONE_NUMBER)}
            errorMessage={errorMessage.phoneNumber}
          />
        </div>
        <div className={styles.controller}>
          <PrimaryButton
            text={t('button.continue')}
            onClick={sendOTPCode}
            style={{
              width: '100%',
            }}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
