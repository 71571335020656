import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import CommonReducer from './common/reducer';
import UserReducer from './user/reducer';
import GeneralReducer from './general/reducer';
import GlobalReducer from './global/reducer';

export const store = configureStore({
  reducer: {
    common: CommonReducer,
    user: UserReducer,
    general: GeneralReducer,
    global: GlobalReducer,
  },
});

const rootReducer = combineReducers({
  common: CommonReducer,
  user: UserReducer,
  general: GeneralReducer,
  global: GlobalReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;
export const { dispatch } = store;

export default store;
