import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { IoChevronForward } from 'react-icons/io5';
import { Skeleton } from 'antd-mobile';
import { ActionPanel } from 'components/panel';
import { useDisclosure } from 'hooks';
import { showData } from 'utils';
import { ReactSVG } from 'react-svg';

type Props = {
  isSkeleton?: boolean;
  panelTitle?: string;
  value?: string | number;
  options?: { title: string; value: string | number }[];
  isDisabled?: boolean;
  onSelect?: (key: string | number) => void;
  iconUrl?: string;
  placeHolder?: string;
};

function MainSelector({
  isSkeleton = false,
  isDisabled = false,
  panelTitle = '',
  value = '',
  options = [],
  onSelect,
  iconUrl = '',
  placeHolder = '',
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSelectOption = (key: string | number) => () => {
    onSelect && onSelect(key);
    onClose();
  };

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);

  return (
    <div className={styles.border}>
      <div className={styles.borderWrapper}>
        <div
          className={styles.selectorWrapper}
          onClick={onOpen}
          style={{
            opacity: isDisabled ? '0.8' : '1',
          }}
        >
          <div className={styles.blockContent} onClick={onOpen}>
            {iconUrl && (
              <div className={styles.iconFront}>
                <ReactSVG src={iconUrl} />
              </div>
            )}
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-sm)',
                  width: '100%',
                }}
              />
            ) : (
              <p className="color-black font-sm">
                {value ? showData(selectedOption?.title) : showData(placeHolder)}
              </p>
            )}
            <IoChevronForward
              className={styles.icon}
              color="var(--primary-color)"
              fontSize="20px"
            />
          </div>
          <ActionPanel
            actionTitle={panelTitle}
            isOpen={!isDisabled && isOpen}
            onClose={onClose}
            actions={options.map((option) => ({
              text: option.title,
              key: option.value,
              onClick: handleSelectOption(option.value),
            }))}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(MainSelector);
