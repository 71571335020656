import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { FloatingPanel, FloatingPanelProps } from 'antd-mobile';

type Props = {
  children: React.ReactNode;
  anchors: number[];
  refPanel?: any;
  onClose?: () => void;
};

function BottomPanel({
  children,
  anchors = [],
  refPanel,
  onClose,
  ...nest
}: Props & FloatingPanelProps) {
  const newRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleClick = (e: any) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      onClose && onClose();
    }
  };

  return (
    <>
      <div ref={newRef}>
        <FloatingPanel
          ref={refPanel}
          anchors={anchors}
          className={styles.panel}
          handleDraggingOfContent={false}
          {...nest}
        >
          {children}
        </FloatingPanel>
      </div>
    </>
  );
}

export default React.memo(BottomPanel);
