import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatTime, showData } from 'utils';
import { COMMON_STATUS } from 'types';
import { Skeleton } from 'antd-mobile/es/components/skeleton/skeleton';

type Props = {
  ptName?: string;
  status?: COMMON_STATUS;
  startTime?: string;
  endTime?: string;
  isSkeleton?: boolean;
  onClick?: () => void;
};

function HomePagePTSessionCard({ ptName, endTime, startTime, isSkeleton = false, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.border} onClick={onClick}>
      <div className={styles.borderWrapper}>
        <div className={styles.cardWrapper}>
          <div className={styles.information}>
            <p className="font-sm font-bold color-blue">{t('title.pt-session-coming')}</p>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <p className="color-back font-bold font-md">{showData(ptName)}</p>
            )}
            <div className={styles.rangeTime}>
              <div className={styles.rangeItem}>
                <p className="color-back font-sm">{t('title.start-time')}</p>
                {isSkeleton ? (
                  <Skeleton
                    animated
                    style={{
                      height: 'var(--font-md)',
                    }}
                  />
                ) : (
                  <p className="color-back font-bold font-md">{showData(formatTime(startTime))}</p>
                )}
              </div>
              <div className={styles.rangeItem}>
                <p className="color-back font-sm">{t('title.end-time')}</p>
                {isSkeleton ? (
                  <Skeleton
                    animated
                    style={{
                      height: 'var(--font-md)',
                    }}
                  />
                ) : (
                  <p className="color-back font-bold font-md">{showData(formatTime(endTime))}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomePagePTSessionCard);
