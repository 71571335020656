import { THEME_COLOR } from 'constant';
import { PTBookingContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PTBookingPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout title={'title.booking'} isHideFooter isWhiteBg isHideBottomSpace>
      <PTBookingContainer />
    </SecondaryLayout>
  );
}
