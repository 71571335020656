import { APIResponse } from 'types';
import { AxiosGW } from './axios.service';
import { AddVNPayAddCardUrlReq, PayWithPayOSReq, PayWithVNPayCardReq } from 'types/payment.type';

export default class PaymentService {
  async getExistCards() {
    return (await AxiosGW.get(`/api/v1/payment/vnpay/tokens`)).data as APIResponse;
  }

  async addVNPayAddCardUrl(payload: AddVNPayAddCardUrlReq) {
    const { cancelUrl, cardType, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/create`,
        {
          cancelUrl,
          cardType,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payWithVNPayCard(payload: PayWithVNPayCardReq) {
    const { vnpayTokenId, contractId, cancelUrl, locale, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/vnpay/token/${vnpayTokenId}/contract/${contractId}/pay`,
        {
          cancelUrl,
          locale,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }

  async payWithPayOS(payload: PayWithPayOSReq) {
    const { contractId, cancelUrl, returnUrl } = payload;

    return (
      await AxiosGW.post(
        `/api/v1/payment/payos/contract/${contractId}/pay`,
        {
          cancelUrl,
          returnUrl,
        },
        {
          isDisableToast: true,
        },
      )
    ).data as APIResponse;
  }
}
