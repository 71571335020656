import { ReferralContainer } from 'containers';
import { BlankBackLayout } from 'layouts';

export default function ReferralPage() {
  return (
    <BlankBackLayout>
      <ReferralContainer />
    </BlankBackLayout>
  );
}
