import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  isShowToast: boolean;
  isShowLoadingToast: boolean;
  toastContent: {
    isError?: boolean;
    code?: number;
    message?: string;
    description?: {
      status?: number;
      message?: string;
    };
  };
}

const initialState: State = {
  isLoading: false,
  isShowToast: false,
  isShowLoadingToast: false,
  toastContent: {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state: State, action) => {
      if (state.isLoading != action.payload) {
        state.isLoading = action.payload;
      }
    },
    setLoadingToast: (state: State, action) => {
      state.isShowLoadingToast = action.payload;
    },
    showToast: (state: State, action) => {
      state.isShowToast = true;
      state.toastContent = action.payload;
    },
    clearToast: (state: State) => {
      state.isShowToast = false;
    },
  },
});

export const { setLoading, showToast, clearToast, setLoadingToast } = commonSlice.actions;
export default commonSlice.reducer;
