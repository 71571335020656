/* eslint-disable lines-around-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { Image, ImageViewer, Skeleton } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import { getImageUrl, showData } from 'utils';

type Props = {
  onRegister?: () => void;
  ptName?: string;
  groupName?: string;
  imageUrl?: string;
  isSkeleton?: boolean;
  isHaveContract?: boolean;
};

function PTCard({
  ptName,
  groupName,
  imageUrl,
  isSkeleton = false,
  isHaveContract = false,
  onRegister,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.blockContent}>
        <div className={styles.info}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="font-sm color-white">{showData(ptName)}</p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-us)',
              }}
            />
          ) : (
            <p className="font-us color-white">{showData(groupName)}</p>
          )}
        </div>
        <SecondaryButton
          disabled={isSkeleton}
          text={t('title.register')}
          variant="solid"
          onClick={onRegister}
        />
      </div>
      <div className={styles.image}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          <Image src={getImageUrl(String(imageUrl))} width="auto" height="100%" />
        )}
      </div>
    </div>
  );
}

export default React.memo(PTCard);
