import { Switch } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils';

type Props = {
  activeText?: string;
  defaultText?: string;
  isChecked?: boolean;
  onChange?: (value: boolean) => void;
};

function CustomSwitch({ activeText, defaultText, isChecked, onChange }: Props) {
  return (
    <div className={styles.wrapper}>
      <p className="font-sm color-black">{showData(defaultText)}</p>
      <Switch className={styles.switch} checked={isChecked} onChange={onChange} />
      <p className="font-sm color-black">{showData(activeText)}</p>
    </div>
  );
}

export default React.memo(CustomSwitch);
