import { THEME_COLOR } from 'constant';
import { ClassDetailContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ClassDetailPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout title={'Yoga Hatha'} isHideFooter isHideBottomSpace isWhiteBg>
      <ClassDetailContainer />
    </SecondaryLayout>
  );
}
