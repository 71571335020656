import { THEME_COLOR } from 'constant';
import PaymentResultContainer from 'containers/MembershipContainer/PlansTab/PaymentResultContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function PaymentResultPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideHeader
    >
      <PaymentResultContainer />
    </SecondaryLayout>
  );
}
