import { Toast } from 'antd-mobile';
import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils/common';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearToast } from 'stores/common';
import { IoCheckmarkCircle, IoWarning } from 'react-icons/io5';

type Props = {
  isError?: boolean;
  code?: string | number;
  isOpen?: boolean;
  duration?: number;
};

function ToastLoadingOverlay({
  isError = false,
  isOpen = false,
  duration = 1500,
  code = '',
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (isError) return t(`error-message-code.${code}`);
    else return t(`success-message-code.${code}`);
  }, [code, isError]);

  useEffect(() => {
    if (isOpen) {
      Toast.show({
        maskClassName: styles.toastWrapper,
        icon: isError ? (
          <IoWarning fontSize="108px" color="var(--high-light-color)" />
        ) : (
          <IoCheckmarkCircle fontSize="108px" color="var(--sub-high-light-color)" />
        ),
        content: (
          <p
            className="color-white font-medium font-sm"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {showData(code ? message : '')}
          </p>
        ),
        duration: duration,
        position: 'center',
        afterClose: () => {
          dispatch(clearToast());
        },
      });
    }
  }, [isOpen, isError, message, duration]);

  return <></>;
}

export default React.memo(ToastLoadingOverlay);
