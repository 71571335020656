import { Swiper } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';

type Props = {
  items?: React.ReactNode[];
  emptyElement?: React.ReactNode;
};

function CardHaftSwiper({ items = [], emptyElement }: Props) {
  return items.length > 0 ? (
    <Swiper
      className={styles.listItem}
      trackOffset={0}
      slideSize={items?.length === 1 ? 100 : 90}
      allowTouchMove
      indicator={false}
      stuckAtBoundary={true}
      defaultIndex={0}
    >
      {items?.map((item, idx) => {
        return (
          <Swiper.Item key={idx}>
            <div className={styles.inner}>{item}</div>
          </Swiper.Item>
        );
      })}
    </Swiper>
  ) : (
    <div
      style={{
        padding: '0 0 16px',
      }}
    >
      {emptyElement}
    </div>
  );
}

export default React.memo(CardHaftSwiper);
