import React from 'react';
import styles from './styles.module.scss';
import { Skeleton } from 'antd-mobile';
import { formatTime, getImageUrl, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isSkeleton?: boolean;
  name?: string;
  duration?: number;
  beginAt?: string;
  imageUrl?: string;
  onClick?: () => void;
};

function ClassCard({
  name,
  duration,
  beginAt,
  imageUrl = '',
  onClick = () => {
    return;
  },
  isSkeleton = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={styles.cardWrapper}
      onClick={onClick}
      style={{
        backgroundImage: isSkeleton
          ? ''
          : `url(${imageUrl ? getImageUrl(imageUrl) : '/images/logo/app-icon.png'})`,
      }}
    >
      {isSkeleton && (
        <Skeleton
          animated
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <div className={styles.moreInfo}>
        <div className={styles.block}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <p className="font-lg font-bold color-white">{showData(name)}</p>
          )}
        </div>
        <div className={styles.block}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '40%',
              }}
            />
          ) : (
            <p className="font-lg color-white">{showData(formatTime(beginAt))}</p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '40%',
              }}
            />
          ) : (
            <p className="font-lg color-white">
              {t('title.minute-holder', { value: showData(duration) })}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ClassCard);
