import { PullToReload } from 'components';
import SvgIcon from 'components/shared/SVGIcon';
import { useDirection } from 'hooks';
import React from 'react';

function BlankBackLayout({ children }: { children: React.ReactNode }) {
  const { goBack } = useDirection();

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        flexDirection: 'column',
        position: 'relative',
        background: ' var(--blue-bg-color)',
      }}
    >
      <div
        style={{
          position: 'fixed',
          left: 'var(--space-small)',
          top: 'var(--space-small)',
          zIndex: '1000',
        }}
        onClick={goBack}
      >
        <SvgIcon
          src="/images/icon/left-arrow.svg"
          pathFill="var(--white-text-color)"
          style={{
            width: '15px',
            height: '23px',
          }}
        />
      </div>
      {/* <MainPageTransition> */}
      <PullToReload>{children}</PullToReload>
      {/* </MainPageTransition> */}
    </div>
  );
}

export default React.memo(BlankBackLayout);
