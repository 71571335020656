import { THEME_COLOR } from 'constant';
import ViewContractPdfContainer from 'containers/PTContainer/PTContractContainer/ContractTab/ViewContractPdfContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ViewPTContractPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout title={'title.contract-information'} isHideFooter isWhiteBg isHideBottomSpace>
      <ViewContractPdfContainer />
    </SecondaryLayout>
  );
}
