/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { UserService } from 'services';
import { BookingHistoryType, PTContractType } from 'types';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import styles from './styles.module.scss';
import {
  BottomSpace,
  CustomTitle,
  EmptyCard,
  HistoryBookingCard,
  MainSelector,
  PTBookingCard,
  TimePicker,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from 'hooks';
import moment from 'moment';
import ConfirmModal from 'components/modal/ConfirmModal';

type Props = {};

export default function BookingTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
  const [listContract, setListContract] = useState<PTContractType[]>([]);
  const [selectedContract, setSelectedContract] = useState<PTContractType>();
  const [time, setTime] = useState({
    hour: '00',
    minute: '00',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [rangeTime, setRangeTime] = useState({ from: '', to: '' });
  const [bookingHistory, setBookingHistory] = useState<BookingHistoryType[]>([]);
  const bookingIdRef = useRef('');
  const initNumberOfCard = Math.ceil((window.innerHeight - 64 - 32 - 80 - 93) / 93);

  const getCurrentPTContracts = async () => {
    setIsLoading(true);
    const response = await userService.getCurrentPTContracts();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListContract(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getListBookingHistory = async () => {
    if (selectedContract?.id) {
      setIsLoadingHistory(true);
      const response = await userService.getListBookingPTHistory({
        ptContractId: String(selectedContract?.id),
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setBookingHistory(data);
      }
      delayNavigate(() => setIsLoadingHistory(false));
    }
  };

  const handleChangeTime = (time: { hour: string; minute: string }) => {
    setTime(time);
  };

  const handleBooking = async () => {
    setIsLoadingBooking(true);
    const startDate = moment()
      .set('hour', Number(time.hour))
      .set('minute', Number(time.minute))
      .toISOString();
    const response = await userService.bookingPTContract({
      ptContractId: selectedContract?.id,
      startDate: startDate,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
      onClose();
    }
    delayNavigate(() => setIsLoadingBooking(false));
  };

  const openTimePicker = () => {
    const from = moment().add('minute', 5);
    const to = moment().add('minute', 14);
    setRangeTime({
      from: from.format('HH:mm'),
      to: to.format('HH:mm'),
    });
    setTime({
      hour: from.get('hour').toString().padStart(2, '0'),
      minute: from.get('minute').toString().padStart(2, '0'),
    });
    onOpen();
  };

  const openCancelConfirm = (id: string) => () => {
    bookingIdRef.current = id;
    onOpenConfirm();
  };

  const handleRating = (id: string) => async (rate: number) => {
    const response = await userService.handleRating({
      bookingId: id,
      rating: rate,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
    }
  };

  const handleCancelBooking = async () => {
    const response = await userService.cancelBooking({ bookingId: bookingIdRef.current });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
    }
  };

  useEffect(() => {
    if (listContract.length > 0) {
      setSelectedContract(listContract[0]);
    }
  }, [listContract]);

  useEffect(() => {
    getListBookingHistory();
  }, [selectedContract]);

  useEffect(() => {
    getCurrentPTContracts();
  }, []);

  return (
    <div className={styles.container}>
      {!isLoading && listContract.length === 0 ? (
        <EmptyCard text={t('paragraph.no-pt-contract')} />
      ) : (
        <>
          <div className={styles.controller}>
            <MainSelector
              value={String(selectedContract?.id)}
              options={listContract.map((contract) => ({
                title: String(isVN() ? contract.club?.nameVi : contract.club?.nameEn),
                value: String(contract?.id),
              }))}
              iconUrl="/images/icon/building.svg"
              panelTitle={t('title.select-club')}
              placeHolder={t('title.select-club')}
              isSkeleton={isLoading}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.bookingContent}>
              <PTBookingCard
                isSkeleton={isLoading}
                imageUrl={selectedContract?.ptInfo?.accountDetail?.avatar}
                name={[
                  selectedContract?.ptInfo?.accountDetail?.firstName,
                  selectedContract?.ptInfo?.accountDetail?.lastName,
                ].join(' ')}
                planName={
                  isVN() ? selectedContract?.ptPackage?.nameVi : selectedContract?.ptPackage?.nameEn
                }
                numberSessionLeft={selectedContract?.remainingSessions}
                totalSession={selectedContract?.totalSessions}
                expiredDate={selectedContract?.endDate}
                onBooking={openTimePicker}
              />
            </div>

            <div className={styles.history}>
              <CustomTitle text={t('title.booking-history')} />
              {isLoadingHistory || isLoading ? (
                Array.from(Array(initNumberOfCard).keys()).map((temp) => {
                  return <HistoryBookingCard key={temp} isSkeleton />;
                })
              ) : bookingHistory.length > 0 ? (
                bookingHistory?.map((history) => {
                  return (
                    <HistoryBookingCard
                      key={history.id}
                      startTime={history.startDate}
                      endTime={history.endDate}
                      date={history.startDate}
                      status={history.status}
                      rating={history.rating}
                      onDelete={openCancelConfirm(String(history.id))}
                      onRate={handleRating(String(history.id))}
                    />
                  );
                })
              ) : (
                <EmptyCard text={t('paragraph.no-booking-yet')} />
              )}
            </div>
            <BottomSpace />
          </div>

          <TimePicker
            value={time}
            isOpen={isOpen}
            onClose={onClose}
            onChange={handleChangeTime}
            onConfirm={handleBooking}
            isLoading={isLoadingBooking}
            note={t('title.booking-time-from-to', { from: rangeTime.from, to: rangeTime.to })}
          />
          <ConfirmModal
            isOpen={isOpenConfirm}
            onClose={onCloseConfirm}
            onConfirm={handleCancelBooking}
            confirmTitle={t('paragraph.cancel-booking-confirm')}
          />
        </>
      )}
    </div>
  );
}
