/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import SignatureCanvas from 'react-signature-canvas';
import { SecondaryButton } from 'components';
import ConfirmModal from 'components/modal/ConfirmModal';
import { useDisclosure } from 'hooks';
import PTService from 'services/pt.service';
import { base64ToBlob } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {};

export function PTContractSignContainer({}: Props) {
  const { t } = useTranslation();
  const ptService = new PTService();
  const sigRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [signature, setSignature] = useState(null);

  const handleSignatureEnd = () => {
    setSignature((sigRef.current as any).toDataURL());
  };

  const clearSignature = () => {
    (sigRef.current as any).clear();
    setSignature(null);
  };

  const handleSign = async () => {
    if (signature) {
      const imgBlog = base64ToBlob(signature);
      const response = await ptService.signPTEContract(imgBlog);
      const { code, data } = response;
    }
  };

  return (
    <div className={styles.wrapper}>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: styles.signatureBox }}
        ref={sigRef}
        onEnd={handleSignatureEnd}
      />
      <p
        className="font-sm color-black"
        style={{
          textAlign: 'center',
          marginTop: '12px',
        }}
      >
        {t('paragraph.please-sign')}
      </p>
      {signature && (
        <div className={styles.controller}>
          <SecondaryButton
            onClick={clearSignature}
            text={t('button.re-sign')}
            variant="high-light"
            style={{
              width: '50%',
            }}
          />
          <SecondaryButton
            text={t('button.confirm')}
            variant="high-light-blue"
            style={{
              width: '50%',
            }}
            onClick={onOpen}
          />
        </div>
      )}
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSign}
        confirmTitle={t('paragraph.confirm-before-sign')}
      />
    </div>
  );
}
