import { APIResponse, GetCouponDetailReq, GetListPromotionReq } from 'types';
import { AxiosGW } from './axios.service';

export default class PromotionService {
  async getListCoupons(payload: GetListPromotionReq) {
    const { paymentPlanId } = payload;

    return (await AxiosGW.get(`/api/v1/promotion/payment-plan/${paymentPlanId}/coupons`))
      .data as APIResponse;
  }

  async getCouponDetail(payload: GetCouponDetailReq) {
    const { paymentPlanId, couponId } = payload;

    return (
      await AxiosGW.get(`/api/v1/promotion/payment-plan/${paymentPlanId}/coupon/${couponId}/detail`)
    ).data as APIResponse;
  }
}
