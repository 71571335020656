import styles from './styles.module.scss';
import { ReactSVG } from 'react-svg';
import { SecondaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';

export function PaymentAtCounterContainer() {
  const { t } = useTranslation();
  const { goTo } = useDirection();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className="color-black font-xxxl font-bold">{t('title.success')}</p>
        <ReactSVG src="/images/icon/success.svg" />
        <p
          className="color-black font-xl font-medium"
          style={{
            textAlign: 'center',
            lineHeight: '32px',
          }}
        >
          {t('paragraph.pos-payment')}
        </p>
      </div>
      <div className={styles.bottomButton}>
        <SecondaryButton
          text={t('title.go-home')}
          variant="high-light-blue"
          style={{
            width: '100%',
          }}
          onClick={goTo(ROUTES.HOME)}
        />
      </div>
    </div>
  );
}
