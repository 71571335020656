import { HomeContainer } from 'containers';
import { MainLayout } from 'layouts';

type Props = {};

export default function HomePage({}: Props) {
  return (
    <MainLayout>
      <HomeContainer />
    </MainLayout>
  );
}
