import { APIResponse, CreateFreezeContractReq, GetFreezeTransactionReq } from 'types';
import { AxiosGW } from './axios.service';

export default class FreezeService {
  async createFreezeContract(body: CreateFreezeContractReq) {
    return (await AxiosGW.post('/api/v1/member/freeze-contract', body)).data as APIResponse;
  }

  async getFreezeTransaction(payload: GetFreezeTransactionReq) {
    const { transactionId } = payload;

    return (await AxiosGW.get(`/api/v1/member/freeze-transaction/${transactionId}/detail`))
      .data as APIResponse;
  }

  async getCurrentFreezeContract() {
    return (await AxiosGW.get('/api/v1/member/freeze-contract/current')).data as APIResponse;
  }

  async UnfreezeContract() {
    return (await AxiosGW.put('/api/v1/member/freeze-contract/unfreeze')).data as APIResponse;
  }

  async getFreezeContracts() {
    return (await AxiosGW.get('/api/v1/member/freeze-contract')).data as APIResponse;
  }
}
