import { Button, ButtonProps } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
  variant?: 'solid' | 'outline' | 'high-light';
  isFullWidth?: boolean;
};

function PrimaryButton({
  text,
  variant = 'solid',
  isFullWidth = true,
  ...nest
}: Props & ButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      className={`${styles.wrapper} ${
        variant === 'solid'
          ? styles.solid
          : variant === 'high-light'
          ? styles.highLight
          : styles.outline
      } font-medium font-xl`}
      loadingText={t('button.processing')}
      style={{
        width: isFullWidth ? '100%' : 'fit-content',
      }}
      {...nest}
    >
      {text}
    </Button>
  );
}

export default React.memo(PrimaryButton);
