import React from 'react';
import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { Image, Skeleton } from 'antd-mobile';
import { formatDate, getImageUrl, showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  planName?: string;
  numberSessionLeft?: number;
  totalSession?: number;
  expiredDate?: string;
  isSkeleton?: boolean;
  imageUrl?: string;
  onBooking?: () => void;
};

function PTBookingCard({
  name,
  planName,
  numberSessionLeft = 0,
  totalSession = 0,
  expiredDate,
  isSkeleton = false,
  imageUrl = '',
  onBooking,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.image}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          <Image
            src={getImageUrl(String(imageUrl))}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        )}
      </div>

      <div className={styles.content}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-xl)',
            }}
          />
        ) : (
          <p className="color-black font-xl font-bold">{showData(name)}</p>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-xs)',
              marginTop: '6px',
            }}
          />
        ) : (
          <p
            className="color-black font-xs font-medium"
            style={{
              marginTop: '6px',
            }}
          >
            {showData(planName)}
          </p>
        )}
        <div className={styles.bookingInfo}>
          <div className={styles.itemInfo}>
            <p className="color-black font-xs font-medium">{t('title.remaining-session')}</p>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-xl)',
                  width: '40%',
                }}
              />
            ) : (
              <p className="color-black font-xl font-bold">
                {showData(numberSessionLeft)}/{showData(totalSession)}
              </p>
            )}
          </div>
          <div className={styles.itemInfo}>
            <p className="color-black font-xs font-medium">{t('title.expired-date')}</p>
            {isSkeleton ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-xs)',
                  width: '40%',
                }}
              />
            ) : (
              <p className="color-black font-xs">{showData(formatDate(expiredDate))}</p>
            )}
          </div>
        </div>
        <SecondaryButton
          text="Book"
          variant="high-light"
          disabled={isSkeleton}
          onClick={onBooking}
        />
      </div>
    </div>
  );
}

export default React.memo(PTBookingCard);
