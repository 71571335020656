export const REGEX = {
  /** NOTE: Phone number minimum 10 digits and maximum 12 digits, allow + character */
  PHONE: /^[0-9]{10}$/,

  /** NOTE:  Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character */
  PASSWORD: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,

  /** NOTE: Number length from 10 - 11 digits */
  REFERRAL_CODE: /^[0-9]{10,11}$/,
};
