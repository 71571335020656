import { useState } from 'react';

function useDisclosure(init?: boolean) {
  const [isOpen, setIsOpen] = useState(init ? init : false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, onClose, onOpen, onToggle };
}

export default useDisclosure;
