import React from 'react';

type Props = {};

function BottomSpace({}: Props) {
  return (
    <div
      style={{
        height: '200px',
        minHeight: '200px',
        width: '100%',
      }}
    />
  );
}

export default React.memo(BottomSpace);
