import { APIResponse } from 'types';
import { AxiosGW } from './axios.service';
import {
  BookingPTContractReq,
  CancelBookingReq,
  CheckReferralReq,
  CreateMemberContractReq,
  CreatePTContractReq,
  GetBookingHistoryReq,
  GetCurrentContractsReq,
  GetRegisteredPTContractReq,
  GetTransactionDetailReq,
  RatingReq,
  RegisterPTContractReq,
} from 'types/user.type';

export default class UserService {
  async getContracts(payload: GetCurrentContractsReq) {
    const { status, clubId, isFrozen, isGift, paymentPlanId } = payload;

    return (
      await AxiosGW.get('/api/v1/member/contracts', {
        params: {
          status,
          clubId,
          isFrozen,
          isGift,
          paymentPlanId,
        },
      })
    ).data as APIResponse;
  }

  // async getContracts() {
  //   return (await AxiosGW.get('/api/v1/member/contracts', {})).data as APIResponse;
  // }

  async getMemberContractHome(isHaveCurrent?: boolean) {
    return (
      await AxiosGW.get('/api/v1/member/contracts/home-page', {
        params: {
          ...(isHaveCurrent != undefined ? { isExistsCurrentContract: isHaveCurrent } : {}),
        },
      })
    ).data as APIResponse;
  }

  async getLatestContract(payload: GetCurrentContractsReq) {
    const { clubId } = payload;

    return (
      await AxiosGW.get(`/api/v1/member/club/${clubId}/contract/latest`, {
        params: {
          clubId,
        },
      })
    ).data as APIResponse;
  }

  async checkReferral(payload: CheckReferralReq) {
    const { phoneNumber } = payload;

    return (
      await AxiosGW.post('/api/v1/member/referral/check', {
        phoneNumber,
      })
    ).data as APIResponse;
  }

  async createMemberContract(payload: CreateMemberContractReq) {
    const { couponId, paymentMethodGroupId, paymentPlanId, referralCode } = payload;

    return (
      await AxiosGW.post('/api/v1/member/contract', {
        couponId,
        paymentMethodGroupId,
        paymentPlanId,
        referralCode,
      })
    ).data as APIResponse;
  }

  async getMemberTransactions() {
    return (await AxiosGW.get('/api/v1/member/transactions')).data as APIResponse;
  }

  async getMemberTransactionDetail(payload: GetTransactionDetailReq) {
    const { transactionId } = payload;

    return (await AxiosGW.get(`/api/v1/member/transaction/${transactionId}/detail`))
      .data as APIResponse;
  }

  async registerPTContract(payload: RegisterPTContractReq) {
    const { ptId, ptPackageId } = payload;

    return (await AxiosGW.post(`/api/v1/member/pt-package/${ptPackageId}/pt/${ptId}/register`))
      .data as APIResponse;
  }

  async getRegisteredPTContract(payload: GetRegisteredPTContractReq) {
    const { ptContractId } = payload;

    return (
      await AxiosGW.get(
        `/api/v1/member/pt-contract/${ptContractId}/transaction/waiting-for-payment`,
      )
    ).data as APIResponse;
  }

  async createPTContract(payload: CreatePTContractReq) {
    const { paymentMethodGroupId, ptContractId } = payload;

    return (
      await AxiosGW.post(`/api/v1/member/pt-contract/${ptContractId}/transaction/pay`, {
        paymentMethodGroupId,
      })
    ).data as APIResponse;
  }

  async getCurrentPTContracts() {
    return (
      await AxiosGW.get(`/api/v1/member/pt-contracts`, {
        params: { ptContractStatus: 'CURRENT' },
      })
    ).data as APIResponse;
  }

  async bookingPTContract(payload: BookingPTContractReq) {
    const { ptContractId, startDate } = payload;

    return (
      await AxiosGW.post(`/api/v1/member/pt-contract/${ptContractId}/booking`, {
        startDate,
      })
    ).data as APIResponse;
  }

  async getListBookingPTHistory(payload: GetBookingHistoryReq) {
    const { ptContractId } = payload;

    return (
      await AxiosGW.get(`/api/v1/member/booking/history`, {
        params: { ptContractId },
      })
    ).data as APIResponse;
  }

  async cancelBooking(payload: CancelBookingReq) {
    const { bookingId } = payload;

    return (await AxiosGW.put(`/api/v1/member/booking/${bookingId}/cancel`)).data as APIResponse;
  }

  async getCurrentBooking() {
    return (
      await AxiosGW.get(`/api/v1/member/booking/current`, {
        isDisableToast: true,
      })
    ).data as APIResponse;
  }

  async handleRating(payload: RatingReq) {
    const { bookingId, rating } = payload;

    return (
      await AxiosGW.put(`/api/v1/member/booking/${bookingId}/rating`, {
        rating,
      })
    ).data as APIResponse;
  }

  async verifyIdCard(image: Blob, recognizeType: string) {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('recognitionType', recognizeType);

    return (await AxiosGW.post('/api/v1/fpt/recognize-id/upload', formData)).data as APIResponse;
  }

  async getContractDetail(contractId: string) {
    return (await AxiosGW.get(`/api/v1/member/contract/${contractId}/detail`, {}))
      .data as APIResponse;
  }
}
