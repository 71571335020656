import { THEME_COLOR } from 'constant';
import { AccountContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function AccountPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout
      title={'title.account'}
      titleColor="var(--white-text-color)"
      backgroundColor="var(--primary-color)"
      isHideFooter
      isHideBottomSpace
      isWhiteBg
      isHideHome
      isHideSlogan
    >
      <AccountContainer />
    </SecondaryLayout>
  );
}
