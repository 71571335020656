/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRoutes } from 'react-router';
import Router from './routes/Router';
import { ToastLoadingOverlay, ToastOverlay } from 'components';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import { LocalStorage, STORAGE_KEY, resetScroll } from 'utils';
import 'moment/locale/en-au';
import 'moment/locale/vi';
import { getMessagingToken, requestNotifyPermission } from 'externals/firebase';

const Storage = new LocalStorage();

function App() {
  const routing = useRoutes(Router);
  const { isShowToast, toastContent, isShowLoadingToast } = useSelector(commonSelector);
  const [token, setToken] = useState('');
  const { pathname } = useLocation();

  const grandNotifyPermission = async () => {
    const permission = await requestNotifyPermission();

    if (permission === 'granted') {
      const fcmToken = await getMessagingToken();
      if (fcmToken) {
        Storage.setStorageItem(STORAGE_KEY.NOTIFY_TOKEN, fcmToken);
        setToken(fcmToken);
      }
      console.log('TOKEN: ', fcmToken);
    }
  };

  useEffect(() => {
    resetScroll();
  }, [pathname]);

  useEffect(() => {
    Storage.setStorageItem(STORAGE_KEY.IS_APP_OPENED, 'true');
    grandNotifyPermission();
  }, []);

  return (
    <>
      <ToastLoadingOverlay isOpen={isShowLoadingToast} />
      <ToastOverlay isOpen={isShowToast} isError={toastContent.isError} code={toastContent.code} />
      <AnimatePresence exitBeforeEnter>
        {routing}
        {/* <p
          style={{
            position: 'fixed',
            width: '100vw',
            wordBreak: 'break-all',
            top: 0,
          }}
        >
          {token}
        </p> */}
      </AnimatePresence>
    </>
  );
}

export default App;
