import { THEME_COLOR } from 'constant';
import { ChoosePTContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ChoosePTPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.SUB);

  return (
    <SecondaryLayout title={'title.personal-trainer'} isHideFooter isWhiteBg isHideBottomSpace>
      <ChoosePTContainer />
    </SecondaryLayout>
  );
}
