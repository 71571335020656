/* eslint-disable @typescript-eslint/no-unused-vars */
import { Image } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

function OpenAppOverlay() {
  const [isShow, setIsShow] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 3500);
  }, []);

  return createPortal(
    isShow && (
      <div className={styles.container}>
        <div className={styles.logo}>
          <Image
            src="/images/logo/white-logo.png"
            alt="logo"
            style={{
              width: '200px',
              height: 'auto',
            }}
          />

          <p className={`font-xxl color-white font-medium ${styles.animation}`}>
            {t('paragraph.slogan')}
          </p>
        </div>
      </div>
    ),
    document.body,
  );
}

export default React.memo(OpenAppOverlay);
