export const DEFAULT_EMPTY = '-';

export const DELAY_TRANSITION = 300;

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_FORMAT_LOCALE_VN = 'DD MMMM, YYYY';

export const DATE_FORMAT_LOCALE_EN = 'MMMM DD, YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const APP_NAME = 'The New Gym';

export const HEADER_HEIGHT = 54;

export const THEME_COLOR = {
  MAIN: '#F2FAFC',
  SUB: '#009fc2',
  BLACK: '#121212',
  WHITE: '#ffffff',
};

export const UNIT_PRICE = 'vnđ';

export const OTP_RESENT_DURATION = 30;
