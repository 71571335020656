import styles from './styles.module.scss';
import { SpaceProps } from 'antd-mobile';
import SvgIcon from 'components/shared/SVGIcon';
import { ROUTES } from 'constant';
import { useDirection } from 'hooks';

type Props = {};

export default function Header({ ...rest }: Props & SpaceProps) {
  const { goTo } = useDirection();

  return (
    <div className={styles.headerWrapper} {...rest}>
      <div className={styles.iconWrapper} onClick={goTo(ROUTES.ACCOUNT)}>
        <div className={styles.icon}>
          <SvgIcon src="/images/icon/user.svg" />
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <SvgIcon src="/images/icon/head-phone.svg" />
        </div>
        <div className={styles.icon}>
          <SvgIcon src="/images/icon/notify.svg" />
        </div>
      </div>
    </div>
  );
}
